import React, {useEffect} from "react";
import {useParams} from "react-router-dom";
import {RootState, useAppDispatch} from "../../store/store";
import {AlertComponent} from "../../components";
import {useSelector} from "react-redux";
import {CircularProgress} from "@material-ui/core";
import {fetchRelocationCaseFinances, updateRelocationCaseFinances} from "../../store/caseFinanceSlice";
import {useFormik} from "formik";
import {Alert, Spin} from "antd";
import {ImplicitFlowService} from "@common/services";
import {formikKey, mapFormikToFinances} from "./CaseFinanceFormikUtils";
import {ADMIN_USERS} from "appConstants";
import styles from "./CaseFinance.module.scss";

export const CaseFinance: React.FC = () => {
  const dispatch = useAppDispatch();
  const {case_id: id} = useParams<{case_id: string}>();
  const relocationCase = useSelector((state: RootState) => state.caseFinance.relocationCase) as any;
  const comparableUnit = useSelector((state: RootState) => state.caseFinance.comparableUnit) as any;
  const splitStays = useSelector((state: RootState) => state.caseFinance.splitStays) as any;
  const isLoading = useSelector((state: RootState) => state.caseFinance.isLoading) as any;
  const isUpdateLoading = useSelector((state: RootState) => state.caseFinance.isUpdateLoading) as any;
  const isUpdateError = useSelector((state: RootState) => state.caseFinance.isUpdateError) as any;
  const isUpdateSuccess = useSelector((state: RootState) => state.caseFinance.isUpdateSuccess) as any;
  const {email} = ImplicitFlowService.getAuthenticatedUser();
  const isMSFM = relocationCase?.attributes.mid_stay_forced_move;
  const isSplitStay = splitStays.length;
  const isComparableUnit = !!comparableUnit;
  const defaultReason = "OG Reservation cancelled before initiation";

  // Formik Initial Values
  const initialValues = {
    // Original reservation
    finance_fees_pre: relocationCase?.attributes?.finance_fees_pre,
    finance_taxes_pre: relocationCase?.attributes?.finance_taxes_pre,
    finance_tp_pre: relocationCase?.attributes?.finance_tp_pre,
    finance_rent_pre: relocationCase?.attributes?.finance_rent_pre,

    // Reservation After Adjustment
    finance_fees_post: relocationCase?.attributes?.finance_fees_post,
    finance_taxes_post: relocationCase?.attributes?.finance_taxes_post,
    finance_tp_post: relocationCase?.attributes?.finance_tp_post,
    finance_rent_post: relocationCase?.attributes?.finance_rent_post,

    notes: defaultReason,
  } as any;

  // New reservation, this will containdata if no SplitStay
  if (!isSplitStay) {
    initialValues.new_finance_fees = comparableUnit?.attributes?.finance_fees;
    initialValues.new_finance_taxes = comparableUnit?.attributes?.finance_taxes;
    initialValues.new_finance_rent = comparableUnit?.attributes?.finance_rent;
    initialValues.new_finance_tp = comparableUnit?.attributes?.finance_tp;
  }

  // Add Dynamic Splistays
  splitStays.forEach((splitStay: any, idx: number) => {
    initialValues[formikKey("fees", splitStay.id)] = splitStay.attributes.finance_fees;
    initialValues[formikKey("taxes", splitStay.id)] = splitStay.attributes.finance_taxes;
    initialValues[formikKey("rent", splitStay.id)] = splitStay.attributes.finance_rent;
    initialValues[formikKey("tp", splitStay.id)] = splitStay.attributes.finance_tp;
  });

  // Formik Form
  const formik = useFormik({
    enableReinitialize: true,
    initialValues,
    onSubmit: async (values) => {
      const response = (await dispatch(
        updateRelocationCaseFinances({id: relocationCase.id, data: mapFormikToFinances(values, splitStays, email)})
      )) as any;
      if (response.error) return; // if an error happens skip redirecting
      setTimeout(() => {
        window.location.href = `/case/${relocationCase.id}/wrapup`; // TODO: once wrapup is using redux-tk change this for useNavigate
      }, 3000);
    },
  });

  // Fetch Finances
  useEffect(() => {
    // @ts-ignore
    dispatch(fetchRelocationCaseFinances(id));
  }, []);

  // Early returns
  if (isLoading)
    return (
      <div className={styles.containerBlocked}>
        <Spin size="large">
          <Alert message="Loading Case Information" description="We are preparing all the information you need." type="info" />
        </Spin>
      </div>
    );
  if (!ADMIN_USERS.includes(email)) return <div className={styles.containerBlocked}>You're not allowed to access this page</div>;
  //   if (!isCaseResolved(relocationCase)) return <div className={styles.containerBlocked}>This case needs to be wrapped up before updating it's finances.</div>

  // extract data for display
  const {legacy_reservation_id} = relocationCase.attributes;

  return (
    <div className={styles.container}>
      <h2>Case Finance Update</h2>

      <form onSubmit={formik.handleSubmit}>
        <div className={styles.financeBox}>
          <div className={styles.financeTypeColumn}>
            <div></div>
            <div>Res Id</div>
            <div>Rent</div>
            <div>Fees</div>
            <div>Tp</div>
            <div>Taxes</div>
          </div>

          {/* Original Reservation */}
          <div className={styles.financeAmountColumn}>
            <div className={styles.title}>Original Res</div>
            <div>
              <a href="#">{legacy_reservation_id}</a>
            </div>
            <div>
              <input id="finance_rent_pre" type="number" step="any" onChange={formik.handleChange} value={formik.values.finance_rent_pre} />
            </div>
            <div>
              <input id="finance_fees_pre" type="number" step="any" onChange={formik.handleChange} value={formik.values.finance_fees_pre} />
            </div>
            <div>
              <input id="finance_tp_pre" type="number" step="any" onChange={formik.handleChange} value={formik.values.finance_tp_pre} />
            </div>
            <div>
              <input id="finance_taxes_pre" type="number" step="any" onChange={formik.handleChange} value={formik.values.finance_taxes_pre} />
            </div>
          </div>

          {/* Original Reservation After Adjustment */}
          {isMSFM && (
            <div className={styles.financeAmountColumn}>
              <div className={styles.title}>After Adjustment</div>
              <div>
                <a href="#">{legacy_reservation_id}</a>
              </div>
              <div>
                <input id="finance_rent_post" type="number" step="any" onChange={formik.handleChange} value={formik.values.finance_rent_post} />
              </div>
              <div>
                <input id="finance_fees_post" type="number" step="any" onChange={formik.handleChange} value={formik.values.finance_fees_post} />
              </div>
              <div>
                <input id="finance_tp_post" type="number" step="any" onChange={formik.handleChange} value={formik.values.finance_tp_post} />
              </div>
              <div>
                <input id="finance_taxes_post" type="number" step="any" onChange={formik.handleChange} value={formik.values.finance_taxes_post} />
              </div>
            </div>
          )}

          {/* Comparable Unit Finances */}
          {!isSplitStay && isComparableUnit && (
            <div className={styles.financeAmountColumn}>
              <div className={styles.title}>New Reservation</div>
              <div>
                <a href="#">{comparableUnit.attributes.legacy_reservation_id}</a>
              </div>
              <div>
                <input id="new_finance_rent" type="number" step="any" onChange={formik.handleChange} value={formik.values.new_finance_rent} />
              </div>
              <div>
                <input id="new_finance_fees" type="number" step="any" onChange={formik.handleChange} value={formik.values.new_finance_fees} />
              </div>
              <div>
                <input id="new_finance_tp" type="number" step="any" onChange={formik.handleChange} value={formik.values.new_finance_tp} />
              </div>
              <div>
                <input id="new_finance_taxes" type="number" step="any" onChange={formik.handleChange} value={formik.values.new_finance_taxes} />
              </div>
            </div>
          )}

          {/* Render SplitStay Finances && Dynamical Map to Formik Values */}
          {splitStays.map((splitStay: any, idx: number) => {
            const {legacy_reservation_id} = splitStay.attributes;
            return (
              <div className={styles.financeAmountColumn} key={idx}>
                <div className={styles.title}>Res {idx + 1}</div>
                <div>
                  <a href="#">{legacy_reservation_id}</a>
                </div>
                <div>
                  <input
                    id={formikKey("rent", splitStay.id)}
                    type="number"
                    step="any"
                    value={formik.values[formikKey("rent", splitStay.id)]}
                    onChange={formik.handleChange}
                  />
                </div>
                <div>
                  <input
                    id={formikKey("fees", splitStay.id)}
                    type="number"
                    step="any"
                    value={formik.values[formikKey("fees", splitStay.id)]}
                    onChange={formik.handleChange}
                  />
                </div>
                <div>
                  <input
                    id={formikKey("tp", splitStay.id)}
                    type="number"
                    step="any"
                    value={formik.values[formikKey("tp", splitStay.id)]}
                    onChange={formik.handleChange}
                  />
                </div>
                <div>
                  <input
                    id={formikKey("taxes", splitStay.id)}
                    type="number"
                    step="any"
                    value={formik.values[formikKey("taxes", splitStay.id)]}
                    onChange={formik.handleChange}
                  />
                </div>
              </div>
            );
          })}
        </div>
        <br />
        <br />
        Why are you updating this finances?
        <br />
        <select id="notes" value={formik.values.notes} onChange={formik.handleChange}>
          <option>{defaultReason}</option>
          <option>OG Reservation not captured correctly</option>
          <option>ReOpened case</option>
          <option>New reservation processed</option>
          <option>New reservation modified after wrap-up</option>
          <option>Incorrect case resolution</option>
          <option>Adjusted New Reservation Totals Prior to Wrapping</option>
        </select>
        <br />
        <br />
        <button type="submit">Update</button>
        {isUpdateLoading && <CircularProgress color="inherit" size={25} style={{marginLeft: 10}} />}
        {isUpdateSuccess && (
          <AlertComponent
            text={<>Finances updated successfully! Redirecting to Wrapup...</>}
            className="success"
            iconSize="25"
            setShowAlert={() => {}}
          />
        )}
        {isUpdateError && (
          <AlertComponent
            text={<>Finances failed to update, this case is probably in a bad state.</>}
            className="error"
            iconSize="25"
            setShowAlert={() => {}}
          />
        )}
      </form>
    </div>
  );
};
