import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {ReservationDetails} from "../typing";
import {EventService, RelocationService} from "@common/services";
import {fetchRelocationCaseProfile, fetchRelocationCase, isCaseOpen} from "./caseProfileSlice";
import {
    RelocationCaseAttributesType,
    RelocationCaseReOpenAttributesType,
    RelocationCaseUpdateGuestRefundAttributesType,
    RelocationRequestAttributesType,
    ReservationHoldPostAttributesType,
} from "@common/typing";
import {TurnoversTypes, WrapUpOptions, DEFAULT_REFUND_REASON, guestSatisfactionRefundReasons, OptionsAvailable} from "appConstants";
import {floatFixed, getUserInfo, sumFinances} from "../utils";
import dayjs from "dayjs";
import {datadogLogs} from "@datadog/browser-logs";
import {EventSources, EventTypes} from "@common/utils";
import isNil from "lodash/isNil";
import isEmpty from "lodash/isEmpty";

// Service Singletons
const relocationService = RelocationService.getInstance();

export const patchRelocationCase = createAsyncThunk("wrapUp/patchRelocationCase", async (data: {id: number; payload: any}) => {
    const response = await relocationService.updateRelocationCase(data.id, data.payload);
    return response.data;
});

export const updateBilling = createAsyncThunk("wrapUp/updateBilling", async (data: undefined, {getState, dispatch}) => {
    const state = getState() as any;
    let relocationCaseData: RelocationCaseAttributesType;
    if (state.caseProfile.relocationCase.id) {
        relocationCaseData = {
            owner_billed_amount: state.wrapUp.billing.ownerAmount,
            owner_billed_percent: state.wrapUp.billing.ownerPercent,
        } as RelocationCaseAttributesType;
    } else {
        relocationCaseData = {
            owner_billed_percent: state.wrapUp.billing.ownerPercent,
            owner_billed_amount: state.wrapUp.billing.ownerAmount,
            guest_billed_amount: state.wrapUp.billing.guestAmount,
        } as RelocationCaseAttributesType;
    }

    // @ts-ignore
    await dispatch(patchRelocationCase({id: state.caseProfile.relocationCase.id, payload: relocationCaseData}));
});

const getHoldTypeAndSubType = (clean_after_stay_owner_billed: boolean) => ({
    reservation_type: clean_after_stay_owner_billed ? "owner_hold" : "vacasa_hold",
    sub_type: clean_after_stay_owner_billed ? "other" : "out of order",
});

const createHold = async (payload: ReservationHoldPostAttributesType) => {
    try {
        return await relocationService.createReservationHold(payload);
    } catch (error: any) {
        EventService.dispatch(datadogLogs, {
            title: "create Reservation Hold for CAS error",
            message: "Clean After Stay reservation has failed to be created",
            type: EventTypes.CASE_CAS_CREATED_ERROR,
            source: EventSources.UI,
            level: EventService.WARNING_LEVEL,
            data: {error, payload},
        });
        datadogLogs.logger.error("createHold error", error);
        throw error;
    }
};

const putCleanAfterStay = createAsyncThunk("wrapUp/putCleanAfterStay", async (data: undefined, {getState, rejectWithValue}) => {
    const state = getState() as any;
    const relocationRequest = state.caseProfile.relocationRequest?.attributes;
    const relocationCase = state.caseProfile.relocationCase?.attributes;
    const cleanAfterStay = state.wrapUp.details.cleanAfterStay;
    if (!cleanAfterStay) return null;

    try {
        await removeTurnoverForCAS(relocationRequest, relocationCase);
        return await createCleanAfterStayHold(relocationRequest, state.wrapUp.details.cleanOwnerBilled);
    } catch (error) {
        datadogLogs.logger.error("Create CAS Hold", {error, relocation_case_id: state.caseProfile.relocationCase.id});
        return rejectWithValue("Create CAS Hold");
    }
});

async function removeTurnoverForCAS(relocationRequest: RelocationRequestAttributesType, relocationCase: RelocationCaseAttributesType) {
    try {
        const {end_date, extended_end_date, legacy_unit_id} = relocationRequest;
        const cleanAfterStayDate = extended_end_date ? extended_end_date : end_date;

        const turnoverPayload = {
            unit_id: legacy_unit_id,
            request_id: relocationRequest.id,
            block_turnover: true,
            start_date: dayjs(cleanAfterStayDate).subtract(1, "days").format("YYYY-MM-DD"),
            end_date: cleanAfterStayDate,
            turnover: TurnoversTypes.TURNOVER_NO_CHECK_IN_OR_OUT,
        };

        return await relocationService.setTurnoverPeriod(turnoverPayload);
    } catch (error) {
        datadogLogs.logger.error("Update Turnover Latest Day", {error, relocation_case_id: relocationCase.id});
        throw error;
    }
}

async function createCleanAfterStayHold(relocationRequest: RelocationRequestAttributesType, cleanOwnerBilled: boolean | null) {
    // Get case id and end_date/extended for turnover and hold
    const {end_date, extended_end_date, legacy_unit_id} = relocationRequest;
    const cleanAfterStayDate = extended_end_date ? extended_end_date : end_date;

    const {email: userEmail} = getUserInfo();

    // Create hold
    const holdPayload = {
        origin: {
            source_name: "VACASA_ADMIN",
            created_by_email: userEmail,
        },
        unit: {
            id_type: "LEGACY_UNIT",
            id: legacy_unit_id.toString(),
        },
        stay: {
            check_in: dayjs(cleanAfterStayDate).subtract(1, "days").format("YYYY-MM-DD"),
            check_out: cleanAfterStayDate,
            adults: 1,
            reservation_type: getHoldTypeAndSubType(cleanOwnerBilled || false).reservation_type,
            sub_type: getHoldTypeAndSubType(cleanOwnerBilled || false).sub_type,
            internal_note: "relocation",
            clean_after_stay: true,
        },
    } as any;

    return (await createHold(holdPayload))?.data?.attributes?.legacy_reservation_id;
}

export const save = createAsyncThunk("wrapUp/save", async (selectedOption: number, {getState, dispatch}) => {
    const state = getState() as any;
    let relocationCaseData: RelocationCaseAttributesType;
    const currentOriginResData = state.wrapUp.finances.origin.current;
    const snapshotOriginPreResData = state.wrapUp.finances.origin.snapshotPre;

    async function getCASLegacyID() {
        try {
            return await dispatch(putCleanAfterStay()).unwrap();
        } catch {
            return null;
        }
    }

    switch (selectedOption) {
        case WrapUpOptions.STAY_IN_HOME:
            relocationCaseData = {
                id: state.caseProfile.relocationCase.id,
                guest_refund_amount: state.wrapUp.refund.amount,
                relocation_case_status_id: RelocationService.STATUS_DONE,
                relocation_case_resolution_id: RelocationService.RESOLUTION_STAYED_IN_HOME,
                wrapup_note: state.wrapUp.refund.note,
            } as RelocationCaseAttributesType;
            break;
        case WrapUpOptions.FM_NO_LONGER_NEEDED:
            relocationCaseData = {
                guest_awareness: state.wrapUp.guestAwareness,
                relocation_case_status_id: RelocationService.STATUS_DONE,
                relocation_case_resolution_id: RelocationService.RESOLUTION_FM_NO_LONGER_NEEDED,
            } as RelocationCaseAttributesType;
            break;
        case WrapUpOptions.CANCEL_AND_FSC:
        case WrapUpOptions.CANCEL_AND_REFUND:
            let caseResolution =
                selectedOption === WrapUpOptions.CANCEL_AND_REFUND ? RelocationService.RESOLUTION_CANCELLED_REFUND : RelocationService.RESOLUTION_FSC;
            relocationCaseData = {
                owner_billed_amount: state.wrapUp.billing.ownerAmount,
                owner_billed_percent: state.wrapUp.billing.ownerPercent,
                guest_billed_amount: state.wrapUp.billing.guestAmount,
                guest_refund_amount: state.wrapUp.refund.amount,
                preventable: state.wrapUp.details.fmPreventable,
                relocation_case_status_id: RelocationService.STATUS_DONE,
                relocation_case_resolution_id: caseResolution,
                clean_after_stay: state.wrapUp.details.cleanAfterStay,
                clean_after_stay_owner_billed: state.wrapUp.details.cleanOwnerBilled,
                mid_stay_forced_move: state.wrapUp.isMidStayFM,
                guest_refund_reason: state.wrapUp.refund.reason,
                clean_after_stay_legacy_reservation_id: await getCASLegacyID(),
            } as RelocationCaseAttributesType;

            //cancel and refund or cancel an fsc can also be a mid stay under certain conditions
            //given the case save post finances using the more updates values of the res
            if (state.wrapUp.financesVerifiedByUser && state.wrapUp.isMidStayFM) {
                relocationCaseData = {
                    ...relocationCaseData,
                    ...{
                        finance_rent_post: currentOriginResData?.rent,
                        finance_fees_post: currentOriginResData?.fees,
                        finance_tp_post: currentOriginResData?.tp,
                        finance_taxes_post: currentOriginResData?.taxes,
                        finance_difference: floatFixed(currentOriginResData?.total - snapshotOriginPreResData?.total),
                    },
                };
            }

            break;
        case WrapUpOptions.MOVE_TO_NEW_UNIT:
            const compUnit = state.wrapUp.compUnit;
            const destinationResData = state.wrapUp.finances.destination;
            const comparableUnitTypeId = compUnit.attributes.relocation_case_comparable_unit_type_id;

            const midStayTotals = state.wrapUp.finances.midStayFMTotals;

            relocationCaseData = {
                owner_billed_amount: state.wrapUp.billing.ownerAmount,
                owner_billed_percent: state.wrapUp.billing.ownerPercent,
                guest_billed_amount: state.wrapUp.billing.guestAmount,
                guest_refund_amount: state.wrapUp.refund.amount,
                preventable: state.wrapUp.details.fmPreventable,
                clean_after_stay: state.wrapUp.details.cleanAfterStay,
                clean_after_stay_owner_billed: state.wrapUp.details.cleanOwnerBilled,
                relocation_case_status_id: RelocationService.STATUS_DONE,
                relocation_case_resolution_id: RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT,
                selected_relocation_case_comparable_unit_id: compUnit.id,
                mid_stay_forced_move: state.wrapUp.isMidStayFM,
                finance_difference:
                    comparableUnitTypeId === OptionsAvailable.SPLIT_STAY
                        ? floatFixed(sumFinances("total", destinationResData) - snapshotOriginPreResData?.total)
                        : comparableUnitTypeId === OptionsAvailable.OUTSIDE_BOOKING
                        ? floatFixed(compUnit?.attributes?.external_amount - snapshotOriginPreResData?.total)
                        : floatFixed(destinationResData[0]?.total - snapshotOriginPreResData?.total),
                guest_refund_reason: state.wrapUp.refund.reason,
                clean_after_stay_legacy_reservation_id: await getCASLegacyID(),
            } as RelocationCaseAttributesType;

            if (state.wrapUp.financesVerifiedByUser && state.wrapUp.isMidStayFM) {
                relocationCaseData = {
                    ...relocationCaseData,
                    ...{
                        finance_rent_post: currentOriginResData?.rent,
                        finance_fees_post: currentOriginResData?.fees,
                        finance_tp_post: currentOriginResData?.tp,
                        finance_taxes_post: currentOriginResData?.taxes,
                        finance_difference:
                            comparableUnitTypeId === OptionsAvailable.OUTSIDE_BOOKING
                                ? floatFixed(
                                      currentOriginResData?.total +
                                          parseFloat(compUnit?.attributes?.external_amount) -
                                          snapshotOriginPreResData?.total
                                  )
                                : floatFixed(midStayTotals?.total - snapshotOriginPreResData?.total),
                    },
                };
            }
            break;
        default:
            break;
    }
    // @ts-ignore
    dispatch(patchRelocationCase({id: state.caseProfile.relocationCase.id, payload: relocationCaseData}));
});

export const reOpenCase = async (data: {id: number; userEmail: string}) => {
    const relocationCaseData: RelocationCaseReOpenAttributesType = {
        id: data.id,
        updated_by: data.userEmail,
    } as RelocationCaseReOpenAttributesType;
    const response = await relocationService.reOpenRelocationCase(data.id, relocationCaseData);
    return response.data;
};

export const updateGuestSatisfaction = async (data: {id: number; userEmail: string; guestRefundAmount: number}) => {
    const relocationCaseData: RelocationCaseUpdateGuestRefundAttributesType = {
        id: data.id,
        updated_by: data.userEmail,
        guest_refund_amount: data.guestRefundAmount,
    } as RelocationCaseUpdateGuestRefundAttributesType;
    const response = await relocationService.updateRelocationCaseGuestSatisfactionRefund(data.id, relocationCaseData);
    return response.data;
};

export const updateMidStayTotals = createAsyncThunk("wrapUp/updateMidStayTotals", async (data: undefined, {getState, dispatch}) => {
    const state = getState() as any;
    const currentOriginResData = state.wrapUp.finances.origin.current;
    const destinationResData = state.wrapUp.finances.destination;
    const snapshotPostResData = state.wrapUp.finances.origin.snapshotPost;
    const isMidStayFM = state.wrapUp.isMidStayFM;
    const isCaseOpen = state.caseProfile.isCaseOpen;
    const relocationCase = state.caseProfile.relocationCase;
    const unit = state.caseProfile.unit;

    if (!isEmpty(currentOriginResData) && !isEmpty(destinationResData)) {
        const originResFinanceReference = isMidStayFM && !isCaseOpen ? snapshotPostResData : currentOriginResData;
        dispatch(
            setMidStayFMTotals({
                unitId: unit?.attributes?.legacy_unit_id,
                unitName: unit?.attributes?.name,
                resId: relocationCase?.attributes?.legacy_reservation_id,
                rent: originResFinanceReference?.rent + sumFinances("rent", destinationResData),
                fees: originResFinanceReference?.fees + sumFinances("fees", destinationResData),
                tp: originResFinanceReference?.tp + sumFinances("tp", destinationResData),
                taxes: originResFinanceReference?.taxes + sumFinances("taxes", destinationResData),
                total: originResFinanceReference?.total + sumFinances("total", destinationResData),
            })
        );
    }
});

type initialStateType = {
    compUnit: any;
    saving: boolean;
    guestAwareness: boolean | null;
    isMidStayFM: boolean;
    financesVerifiedByUser: boolean;
    displayGuestBilling: boolean;
    creatingHold: boolean;
    finances: {
        origin: {
            current: Partial<ReservationDetails> | null;
            snapshotPre: Partial<ReservationDetails> | null;
            snapshotPost: Partial<ReservationDetails> | null;
        };
        midStayFMTotals: Partial<ReservationDetails> | null;
        destination: Partial<ReservationDetails>[] | null;
    };
    billing: {
        ownerAmount: number;
        ownerPercent: number;
        guestAmount: number;
    };
    refund: {
        amount: number;
        reason: string;
        note: string;
        isValid: boolean;
    };
    details: {
        fmPreventable: boolean;
        cleanAfterStay: boolean | null;
        cleanOwnerBilled: boolean | null;
    };
    alerts: {
        wrapUpCompleted: boolean;
        wrapUpReversed: boolean;
        casHoldExist: boolean;
        cleanAfterStay: boolean;
        cancellation: boolean;
        unSyncFinances: boolean;
        reopenCaseHasError: boolean;
    };
    alertsData: {
        cancellationFailedIds: number[];
        originalResUnitId: number;
        casHoldId: number;
        reopenCaseErrorObject: {
            status: number;
            error: string;
        };
    };
};

const initialState: initialStateType = {
    compUnit: null,
    saving: false,
    guestAwareness: null,
    isMidStayFM: false,
    financesVerifiedByUser: false,
    displayGuestBilling: true,
    creatingHold: false,
    finances: {
        origin: {
            current: null,
            snapshotPre: null,
            snapshotPost: null,
        },
        destination: null,
        midStayFMTotals: null,
    },
    billing: {
        ownerAmount: 0,
        ownerPercent: 0,
        guestAmount: 0,
    },
    refund: {
        amount: 0,
        reason: guestSatisfactionRefundReasons.find((x: any) => x.id === DEFAULT_REFUND_REASON)?.text || "",
        note: "",
        isValid: false,
    },
    details: {
        fmPreventable: false,
        cleanAfterStay: false,
        cleanOwnerBilled: false,
    },
    alerts: {
        wrapUpCompleted: false,
        wrapUpReversed: false,
        casHoldExist: false,
        cleanAfterStay: false,
        cancellation: false,
        unSyncFinances: true,
        reopenCaseHasError: false,
    },
    alertsData: {
        cancellationFailedIds: [],
        originalResUnitId: 0,
        casHoldId: 0,
        reopenCaseErrorObject: {
            status: 400,
            error: "Unable to re-open a case",
        },
    },
};

const wrapUpSlice = createSlice({
    name: "wrapUp",
    initialState,
    reducers: {
        setCompUnit: (state, {payload}) => {
            state.compUnit = payload;
        },
        setDestinationResData: (state, {payload}) => {
            state.finances.destination = payload;
        },
        setMidStayFMTotals: (state, {payload}) => {
            state.finances.midStayFMTotals = payload;
        },
        setGuestSatisfactionAmount: (state, {payload}) => {
            state.refund.amount = payload;
            state.refund.isValid = !isNil(payload);
        },
        setGuestSatisfactionNote: (state, {payload}) => {
            state.refund.note = payload;
        },
        setGuestSatisfactionReason: (state, {payload}) => {
            state.refund.reason = payload;
        },
        setCleanAfterStay: (state, {payload}) => {
            state.details.cleanAfterStay = payload;
        },
        setAlertWrapUpCompleted: (state, {payload}) => {
            state.alerts.wrapUpCompleted = payload;
        },
        setAlertWrapUpReversed: (state, {payload}) => {
            state.alerts.wrapUpReversed = payload;
        },
        setAlertCasHoldExist: (state, {payload}) => {
            state.alerts.casHoldExist = payload;
        },
        setAlertCleanAfterStay: (state, {payload}) => {
            state.alerts.cleanAfterStay = payload;
        },
        setAlertCancellation: (state, {payload}) => {
            state.alerts.cancellation = payload;
        },
        setAlertUnSyncFinances: (state, {payload}) => {
            state.alerts.unSyncFinances = payload;
        },
        setAlertData: (state, {payload}) => {
            state.alertsData = {...state.alertsData, ...payload};
        },
        setAlertReopenError: (state, {payload}) => {
            state.alerts.reopenCaseHasError = payload;
        },
        setGuestAwareness: (state, {payload}) => {
            state.guestAwareness = payload;
        },
        setIsMidStayFM: (state, {payload}) => {
            state.isMidStayFM = payload;
        },
        setFinancesVerifiedByUser: (state, {payload}) => {
            state.financesVerifiedByUser = payload;
        },
        setBillingOwnerAmount: (state, {payload}) => {
            state.billing.ownerAmount = payload;
        },
        setBillingOwnerPercent: (state, {payload}) => {
            state.billing.ownerPercent = payload;
        },
        setBillingGuestAmount: (state, {payload}) => {
            state.billing.guestAmount = payload;
        },
        setOriginCurrentFinances: (state, {payload}) => {
            state.finances.origin.current = payload;
        },
        setFMPreventable: (state, {payload}) => {
            state.details.fmPreventable = payload;
        },
        setCleanOwnerBilled: (state, {payload}) => {
            state.details.cleanOwnerBilled = payload;
        },
        resetAlerts: (state) => {
            state.alerts = {
                wrapUpCompleted: false,
                wrapUpReversed: false,
                casHoldExist: false,
                cleanAfterStay: false,
                cancellation: false,
                unSyncFinances: true,
                reopenCaseHasError: false,
            };
            state.alertsData = {
                cancellationFailedIds: [],
                originalResUnitId: 0,
                casHoldId: 0,
                reopenCaseErrorObject: {
                    status: 400,
                    error: "Unable to re-open a case",
                },
            };
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(patchRelocationCase.pending, (state: any) => {
                state.saving = true;
            })
            .addCase(patchRelocationCase.fulfilled, (state: any) => {
                state.saving = false;
            })
            .addCase(fetchRelocationCaseProfile.fulfilled, (state, {payload}) => {
                const caseAttr = payload.relocationCase.attributes;

                if (!isCaseOpen(caseAttr)) {
                    state.refund = {
                        amount: caseAttr.guest_refund_amount,
                        note: caseAttr.wrapup_note,
                        reason: caseAttr.guest_refund_reason,
                        isValid: true,
                    };

                    state.details = {
                        fmPreventable: caseAttr.preventable,
                        cleanAfterStay: caseAttr.clean_after_stay,
                        cleanOwnerBilled: caseAttr.clean_after_stay_owner_billed,
                    };
                    state.isMidStayFM = caseAttr.mid_stay_forced_move;
                }

                state.billing = {
                    ownerPercent: caseAttr.owner_billed_percent,
                    ownerAmount: caseAttr.owner_billed_amount,
                    guestAmount: caseAttr.guest_billed_amount,
                };

                state.guestAwareness = caseAttr.guest_awareness;

                // set finances
                const unitAndResData = {
                    unitId: payload.unit.attributes?.legacy_unit_id,
                    unitName: payload.unit.attributes?.name,
                    resId: caseAttr?.legacy_reservation_id,
                };

                let rentCurrOrigin, feesCurrOrigin, tpCurrOrigin, taxesCurrOrigin;
                let rentOrigin, feesOrigin, tpOrigin, taxesOrigin;
                let rentOriginPost, feesOriginPost, tpOriginPost, taxesOriginPost;
                state.finances = {
                    origin: {
                        current: {
                            ...unitAndResData,
                            rent: (rentCurrOrigin = payload.reservation.attributes?.rent),
                            fees: (feesCurrOrigin = payload.reservation.attributes?.fee_amount),
                            tp: (tpCurrOrigin = payload.reservation?.attributes?.trip_protection_fee),
                            taxes: (taxesCurrOrigin = payload.reservation.attributes?.tax_amount),
                            total: floatFixed(rentCurrOrigin + feesCurrOrigin + tpCurrOrigin + taxesCurrOrigin),
                        },
                        snapshotPre: {
                            ...unitAndResData,
                            rent: (rentOrigin = caseAttr?.finance_rent_pre),
                            fees: (feesOrigin = caseAttr?.finance_fees_pre),
                            tp: (tpOrigin = caseAttr?.finance_tp_pre),
                            taxes: (taxesOrigin = caseAttr?.finance_taxes_pre),
                            total: floatFixed(rentOrigin + feesOrigin + tpOrigin + taxesOrigin),
                        },
                        snapshotPost: {
                            ...unitAndResData,
                            rent: (rentOriginPost = caseAttr?.finance_rent_post),
                            fees: (feesOriginPost = caseAttr?.finance_fees_post),
                            tp: (tpOriginPost = caseAttr?.finance_tp_post),
                            taxes: (taxesOriginPost = caseAttr?.finance_taxes_post),
                            total: floatFixed(rentOriginPost + feesOriginPost + tpOriginPost + taxesOriginPost),
                        },
                    },
                    destination: [],
                    midStayFMTotals: null,
                };
                state.alerts.unSyncFinances = floatFixed(rentOrigin + feesOrigin + tpOrigin + taxesOrigin) === 0;
            })
            .addCase(fetchRelocationCase.fulfilled, (state, {payload}) => {
                const caseAttr = payload.attributes;
                let rentOrigin, feesOrigin, tpOrigin, taxesOrigin;
                let rentOriginPost, feesOriginPost, tpOriginPost, taxesOriginPost;

                state.finances.origin.snapshotPre = {
                    ...state.finances.origin.snapshotPre,
                    rent: (rentOrigin = caseAttr?.finance_rent_pre),
                    fees: (feesOrigin = caseAttr?.finance_fees_pre),
                    tp: (tpOrigin = caseAttr?.finance_tp_pre),
                    taxes: (taxesOrigin = caseAttr?.finance_taxes_pre),
                    total: floatFixed(rentOrigin + feesOrigin + tpOrigin + taxesOrigin),
                };
                state.finances.origin.snapshotPost = {
                    ...state.finances.origin.snapshotPost,
                    rent: (rentOriginPost = caseAttr?.finance_rent_post),
                    fees: (feesOriginPost = caseAttr?.finance_fees_post),
                    tp: (tpOriginPost = caseAttr?.finance_tp_post),
                    taxes: (taxesOriginPost = caseAttr?.finance_taxes_post),
                    total: floatFixed(rentOriginPost + feesOriginPost + tpOriginPost + taxesOriginPost),
                };
                state.alerts.unSyncFinances = floatFixed(rentOrigin + feesOrigin + tpOrigin + taxesOrigin) === 0;

                state.guestAwareness = caseAttr.guest_awareness;
            })
            .addCase(putCleanAfterStay.pending, (state) => {
                state.creatingHold = true;
            })
            .addCase(putCleanAfterStay.fulfilled, (state) => {
                state.creatingHold = false;
            })
            .addCase(putCleanAfterStay.rejected, (state) => {
                state.alerts.cleanAfterStay = true;
            });
    },
});

export const {
    setCompUnit,
    setDestinationResData,
    setMidStayFMTotals,
    setGuestSatisfactionAmount,
    setGuestSatisfactionNote,
    setGuestSatisfactionReason,
    setCleanAfterStay,
    setAlertWrapUpCompleted,
    setAlertWrapUpReversed,
    setAlertCasHoldExist,
    setAlertCleanAfterStay,
    setAlertCancellation,
    setAlertUnSyncFinances,
    setAlertData,
    setAlertReopenError,
    setGuestAwareness,
    setIsMidStayFM,
    setFinancesVerifiedByUser,
    setBillingGuestAmount,
    setBillingOwnerAmount,
    setBillingOwnerPercent,
    setOriginCurrentFinances,
    setFMPreventable,
    setCleanOwnerBilled,
    resetAlerts,
} = wrapUpSlice.actions;

export default wrapUpSlice.reducer;
