import React, {useState} from "react";
import {AlertComponent} from "../alert/AlertComponent";
import {Modal, Image} from "@vacasa/react-components-lib";
import styles from "./BulkSection.module.scss";
import cx from "classnames";
import {SearchBar} from "../search-bar/SearchBar";
import {CaseHistoryActions, BULK_RESOLVE_ACTION, BULK_ASSIGN_ACTION} from "appConstants";
import {RootState, useAppDispatch} from "../../store/store";
import {postCaseBulkAssignment, postCaseBulkResolve, resetFilters} from "../../store/queueSlice";
import {selectedCaseType, resetCasesSelection} from "../../store/bulkSelectionSlice";
import {useSelector} from "react-redux";
import isEmpty from "lodash/isEmpty";

interface Props {
  mode: typeof BULK_RESOLVE_ACTION | typeof BULK_ASSIGN_ACTION | null;
}

export const BulkSection = ({mode}: Props) => {
  const dispatch = useAppDispatch();

  const casesSelected: selectedCaseType[] = useSelector((state: RootState) => state.bulkSelection.casesSelected);

  const [assignedTo, setAssignedTo] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [wrapupNote, setWrapupNote] = useState("");
  const [hasError, setHasError] = useState(false);

  // Message
  const successMessage =
    mode === BULK_RESOLVE_ACTION
      ? `Bulk resolve was succesful. They’ve been re-assigned to you. Make sure to call Guest(s) to notify them of the the change.`
      : `Bulk assignment was successful.`;
  const errorMessage = "We are unable to process these cases. Please refresh this page and try again.";

  /**
   * Applies Bulk Action
   */
  const handleBulkAction = async () => {
    const historyActionId = mode === BULK_RESOLVE_ACTION ? CaseHistoryActions.COMPLETE : CaseHistoryActions.ASSIGN;
    const relocationCaseIds: number[] = casesSelected.map((cas) => cas.id);

    // validate selected
    if (relocationCaseIds.length === 0) return;

    setIsLoading(true);
    if (historyActionId === CaseHistoryActions.COMPLETE) {
      await dispatch(postCaseBulkResolve({relocationCaseIds, wrapupNote}));
    } else if (historyActionId === CaseHistoryActions.ASSIGN) {
      await dispatch(postCaseBulkAssignment({relocationCaseIds, assignedTo}));
    }
    window.scrollTo(0, 0);
    setIsLoading(false);
    setShowAlert(true);

    // TODO: useTimeout, this can created unexpected side effects
    setTimeout(() => {
      dispatch(resetFilters());
      dispatch(resetCasesSelection());
      setAssignedTo("");
    }, 2000);
  };

  const validateActionButton = () => {
    return (
      ((mode === BULK_RESOLVE_ACTION && wrapupNote?.length !== 0) ||
        (mode === BULK_ASSIGN_ACTION && assignedTo?.length !== 0 && assignedTo?.includes("@vacasa.com"))) &&
      !isEmpty(casesSelected)
    );
  };

  const handleChangeAssignCase = (email: string) => setAssignedTo(email || "");

  return (
    <>
      <Modal showModal={isLoading} setShowModal={setIsLoading} size={"small"} canExit={false}>
        <div className={styles.cancellingMessage}>
          <Image.Spinner style={{display: "flex", minWidth: "46px"}} width={50} />
          <span className={styles.message}>Processing cases. Please wait...</span>
        </div>
      </Modal>
      {showAlert && (
        <div className={styles.alert}>
          <AlertComponent
            text={<>{!hasError ? successMessage : errorMessage}</>}
            className={!hasError ? "success" : "error"}
            setShowAlert={setShowAlert}
          />
        </div>
      )}
      <div className={styles.bulkContainer}>
        {mode === BULK_RESOLVE_ACTION ? (
          <input
            type="text"
            placeholder="Enter reason"
            className={styles.inputResolveBulk}
            value={wrapupNote}
            onChange={(e) => setWrapupNote(e.target.value)}
          />
        ) : mode === BULK_ASSIGN_ACTION ? (
          <>
            <span className={styles.toText}>to</span>
            <SearchBar placeholder="Search" value={assignedTo} onSearchChange={setAssignedTo} handleOnChange={handleChangeAssignCase} />
          </>
        ) : null}
        <button
          className={cx(styles.bulkButton, validateActionButton() ? styles.buttonEnabled : styles.buttonDisabled)}
          disabled={!validateActionButton()}
          onClick={handleBulkAction}
        >
          {mode === BULK_RESOLVE_ACTION ? "Resolve" : "Assign"}
        </button>
      </div>
    </>
  );
};
