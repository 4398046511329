import React from "react";
import {FormSubmissionSteps} from "appConstants";
import {
  TypeOfFmStep,
  UrgentFmStep,
  NonUrgentFmStep,
  BillingStep,
  ResIdStep,
  UnitCodeStep,
  UnavailableDatesStep,
  VacasaBilledStep,
  SplitBilledStep,
  SubmitButton,
  CreateFormError,
} from "../index";
import {RootState, useAppDispatch} from "../../../store/store";
import {createsRelocationRequest, resetSteps, hideConfirmationModal, closeCreateFormFailedAlert} from "../../../store/formSubmissionSlice";
import {Form, Formik} from "formik";
import {useSelector} from "react-redux";
import {Button, Modal} from "@vacasa/react-components-lib";
import image from "../../../assets/images/early_check_in.png";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import styles from "./ResumeStep.module.scss";

export const ResumeStep = () => {
  //Redux State
  const dispatch = useAppDispatch();
  const {stepsPath, formData, createFormFailed} = useSelector((state: RootState) => state.formSubmission);
  const {isCreatingRequest, successSubmissionModalVisible} = useSelector((state: RootState) => state.formSubmission);

  const handleClick = () => {
    dispatch(resetSteps());
    dispatch(hideConfirmationModal());
    dispatch(closeCreateFormFailedAlert());
  };

  return (
    <>
      {/* TODO: Update this array with the correct steps path. Find best way to iterate the path */}
      {stepsPath.includes(FormSubmissionSteps.TYPE_OF_FM) && <TypeOfFmStep title="Type of FM" readOnly={true} />}
      {stepsPath.includes(FormSubmissionSteps.URGENT_FM) && <UrgentFmStep title="Urgent FMs" readOnly={true} />}
      {stepsPath.includes(FormSubmissionSteps.NON_URGENT_FM) && <NonUrgentFmStep title="Non-Urgent FM" readOnly={true} />}
      {stepsPath.includes(FormSubmissionSteps.BILLING) && <BillingStep title="Billing" readOnly={true} />}
      {stepsPath.includes(FormSubmissionSteps.RES_ID) && <ResIdStep title="Res ID" readOnly={true} />}
      {stepsPath.includes(FormSubmissionSteps.UNIT_CODES) && <UnitCodeStep title="Unit Codes" readOnly={true} />}
      {stepsPath.includes(FormSubmissionSteps.UNAVAILABLE_DATES) && <UnavailableDatesStep title="Unavailable Dates" readOnly={true} />}
      {stepsPath.includes(FormSubmissionSteps.SPLIT_BILLED) && <SplitBilledStep title="Percentage Billed" readOnly={true} />}
      {stepsPath.includes(FormSubmissionSteps.VACASA_BILLED) && <VacasaBilledStep title="Approval" readOnly={true} />}
      <Formik
        initialValues={{}}
        onSubmit={async () => {
          await dispatch(createsRelocationRequest(formData));
        }}
      >
        {({handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            <p className={styles.noteText}>
              <strong>NOTE:</strong> You’ll receive an email confirming this submission with a link to an FM case. You’ll be able track the progress.
            </p>

            {/* Error submitting the form */}
            {createFormFailed && <CreateFormError />}

            <div className={generalStyles.formButtons}>
              <SubmitButton
                onProcessing={isCreatingRequest}
                step={FormSubmissionSteps.RESUME}
                previousStep={FormSubmissionSteps.RESUME}
                onSubmit={handleSubmit}
              />
              {!isCreatingRequest && (
                <span className={styles.clearSubmission} onClick={handleClick}>
                  Clear submission
                </span>
              )}
            </div>
          </Form>
        )}
      </Formik>
      <Modal canExit={false} size="small" showModal={successSubmissionModalVisible} setShowModal={() => {}}>
        <div className={generalStyles.modalContent}>
          <img src={image} className={generalStyles.modalImage} alt="image" />
          <h3 className={generalStyles.titleMessage}>Success</h3>
          <p className={generalStyles.descriptionMessage}>
            Submission received. Keep an eye out for a confirmation email with a link to the FM case page.
          </p>
          <Button type="submit" customClass={generalStyles.closePageButton} onClick={handleClick}>
            Close Page
          </Button>
          <span className={styles.submitAnotherFm} onClick={handleClick}>
            Submit another FM
          </span>
        </div>
      </Modal>
    </>
  );
};
