import React from "react";
import {Formik, Field, Form} from "formik";
import {BackButton, HeaderEdit} from "../index";
import {FormSubmissionSteps} from "appConstants";
import {refreshStepPath, updateFormData, updateStep} from "../../../store/formSubmissionSlice";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../../store/store";
import {BillingStepSchema} from "../schemas/formSchemas";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import styles from "./BillingStep.module.scss";
import cx from "classnames";

type BillingStepProps = {
  title: string;
  readOnly: boolean;
};

export const BillingStep = ({title, readOnly}: BillingStepProps) => {
  const dispatch = useAppDispatch();
  const {billed} = useSelector((state: RootState) => state.formSubmission.formData);
  const {currentStep, stepsPath} = useSelector((state: RootState) => state.formSubmission);

  return (
    <div className={generalStyles.stepContainer}>
      <HeaderEdit title={title} readOnly={readOnly} currentStep={FormSubmissionSteps.BILLING} previousStep={FormSubmissionSteps.RESUME} />
      <p className={generalStyles.question}>
        Who is being billed for these moves? {!readOnly && <span className={generalStyles.obligatory}>*</span>}
      </p>
      {!readOnly && (
        <>
          <strong>NOTE:</strong>
          <span className={generalStyles.note}>
            {" "}
            If some reservations are owner-billed and some are Vacasa-billed, please enter the group of moves separately.
          </span>
          <p className={generalStyles.note}>
            (If a Guest is requesting to move and is responsible for the difference in cost, please request assistance in rebooking the guest in the
            <a href="https://vacasa.slack.com/archives/CMULWT5T5" target="_blank">
              {" "}
              #Ops-CX Slack Channel
            </a>{" "}
            as this is not a Forced Move)
          </p>
        </>
      )}
      <Formik
        initialValues={{
          billed,
        }}
        onSubmit={(values) => {
          const currentStep =
            values.billed === "owner-billed"
              ? FormSubmissionSteps.OWNER_BILLED
              : values.billed === "vacasa-billed"
              ? FormSubmissionSteps.VACASA_BILLED
              : FormSubmissionSteps.SPLIT_BILLED;
          dispatch(updateFormData(values));
          dispatch(
            updateStep({
              currentStep,
              previousStep: FormSubmissionSteps.BILLING,
            })
          );
          dispatch(refreshStepPath({currentStep, buttonAction: "next"}));
        }}
        validationSchema={BillingStepSchema}
      >
        {({errors, touched, handleChange, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            {!readOnly ? (
              <>
                <div className={styles.billingContainer}>
                  {/* Billed Section */}
                  <div className={generalStyles.radioOptions} role="group" aria-labelledby="my-radio-group">
                    <div className={generalStyles.radioContainer}>
                      <Field type="radio" name="billed" value="owner-billed" onChange={handleChange} />
                      <span>Owner-billed (100%)</span>
                    </div>
                    <div className={generalStyles.radioContainer}>
                      <Field type="radio" name="billed" value="vacasa-billed" onChange={handleChange} />
                      <span>Vacasa-billed (100%)</span>
                    </div>
                    <div className={generalStyles.radioContainer}>
                      <Field type="radio" name="billed" value="split-billed" onChange={handleChange} />
                      <span>Split between Vacasa and Owner</span>
                    </div>
                  </div>
                </div>
                {touched.billed && errors.billed && <p className={generalStyles.validationError}>{errors.billed}</p>}
              </>
            ) : (
              <span className={generalStyles.response}>{billed}</span>
            )}

            {/* Buttons Section */}
            {!readOnly && (
              <div className={generalStyles.formButtons}>
                <BackButton
                  step={stepsPath[stepsPath.findIndex((element) => element === currentStep) - 1]}
                  previousStep={FormSubmissionSteps.BILLING}
                />
                <button type="submit" className={cx(generalStyles.stepButton, generalStyles.nextButton)}>
                  Next
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
