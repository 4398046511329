import {createSlice} from "@reduxjs/toolkit";
import {NAVIGATION_TABS} from "appConstants";

export interface NavigationTabState {
    activeTab: string;
}

const initialState: NavigationTabState = {
    activeTab: NAVIGATION_TABS.INITIATION,
};

export const navigationTabsSlice = createSlice({
    name: "navigationTabs",
    initialState,
    reducers: {
        setActiveTab: (state, action) => {
            state.activeTab = action.payload;
        },
    },
});

export const {setActiveTab} = navigationTabsSlice.actions;

export default navigationTabsSlice.reducer;
