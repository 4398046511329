import React from "react";
import {updateStep, refreshStepPath, resetBilledInfo, closeCreateFormFailedAlert} from "../../../store/formSubmissionSlice";
import {useAppDispatch} from "../../../store/store";
import {BsPencilSquare} from "react-icons/bs";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import {FormSubmissionSteps} from "appConstants";

type HeaderEditProps = {
  title: string;
  readOnly: boolean;
  currentStep: number;
  previousStep: number;
};

export const HeaderEdit = ({title, readOnly, currentStep, previousStep}: HeaderEditProps) => {
  const dispatch = useAppDispatch();

  const handleClick = () => {
    dispatch(updateStep({currentStep, previousStep}));
    dispatch(refreshStepPath({currentStep, buttonAction: "edit"}));
    dispatch(closeCreateFormFailedAlert());
    previousStep === FormSubmissionSteps.RESUME && dispatch(resetBilledInfo());
  };

  return (
    <>
      <div className={generalStyles.headerEdit}>
        <h3>{title}</h3>
        {readOnly && (
          <span className={generalStyles.editBtn} onClick={handleClick}>
            <BsPencilSquare title="Edit" />
            <span>Edit</span>
          </span>
        )}
      </div>
    </>
  );
};
