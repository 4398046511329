import {RelocationCaseComparableUnitAttributesType, RelocationCaseComparableUnitSplitStaysAttributesType} from "@common/typing";
import {RelocationService, ReservationImplicitService} from "@common/services";
import {getUserInfo} from "../User-Info/user-info";
import {RelocationCaseComparableUnit, RelocationCaseComparableUnitSplitStays} from "../../typing";
import {createCancellationPayload} from "../Reservation/reservation";
import {datadogLogs} from "@datadog/browser-logs";
import _ from "lodash";
import {WrapUpOptions} from "appConstants";

// Service Singletons
const relocationService = RelocationService.getInstance();

/**
 * Cancel comp unit hold and then mark as cancelled in database
 *
 * @param comparableUnit
 * @param callbackErrorIds
 */
export const cancelAndMarkCompUnitWithResAttached = async (
    comparableUnit: RelocationCaseComparableUnit | RelocationCaseComparableUnitSplitStays,
    callbackErrorIds?: Function
): Promise<void> => {
    try {
        await cancelCompUnitHold(comparableUnit);
    } catch (error) {
        if (!_.isNil(callbackErrorIds)) callbackErrorIds([comparableUnit?.attributes?.legacy_reservation_id]);
        throw error;
    }
    await markCompUnitHoldAsCancelled(comparableUnit);
};

/**
 * Cancel a single Comparable unit reservation hold
 *
 * @param comparableUnit
 */
export const cancelCompUnitHold = async (comparableUnit: RelocationCaseComparableUnit | RelocationCaseComparableUnitSplitStays): Promise<void> => {
    try {
        const {email: userEmail} = getUserInfo();
        const reservationImplicitService = ReservationImplicitService.getInstance();
        const reservationUuid = (await reservationImplicitService.getReservation(comparableUnit?.attributes.legacy_reservation_id, false)).data[0]
            ?.id;
        await reservationImplicitService.cancelReservation(reservationUuid, createCancellationPayload("relo", "FORCE_ZERO", userEmail));
    } catch (error) {
        datadogLogs.logger.error("Comparable Unit Canceled", {error, comparableUnit});
        throw error;
    }
};

/**
 * Mark comp unit hold as cancelled in database
 *
 * @param comparableUnit
 */
const markCompUnitHoldAsCancelled = async (comparableUnit: RelocationCaseComparableUnit | RelocationCaseComparableUnitSplitStays): Promise<void> => {
    try {
        const relocationCaseComparableUnitData = {
            reservation_cancelled: true,
        } as RelocationCaseComparableUnitAttributesType | RelocationCaseComparableUnitSplitStaysAttributesType;
        if (_.has(comparableUnit, "attributes.relocation_case_comparable_unit_id")) {
            // Means comp unit belong to split stay table
            await relocationService.updateRelocationCaseComparableUnitSplitStays(
                comparableUnit.id,
                relocationCaseComparableUnitData as RelocationCaseComparableUnitSplitStaysAttributesType
            );
        } else {
            // Else comp unit belong to regular comparable unit table
            await relocationService.updateRelocationCaseComparableUnit(
                comparableUnit.id,
                relocationCaseComparableUnitData as RelocationCaseComparableUnitAttributesType
            );
        }
    } catch (error) {
        datadogLogs.logger.error("Comparable Unit Marked", {error, comparableUnit});
        throw error;
    }
};

/**
 * Cancel every split stay hold that belong to comp unit and mark them as cancelled in database.
 * If at least one of split stay was marked as cancelled, then comp unit is marked too.
 *
 * @param comparableUnit
 * @param callbackErrorIds
 */
export const cancelAndMarkSplitStayCompUnit = async (comparableUnit: RelocationCaseComparableUnit, callbackErrorIds?: Function): Promise<void> => {
    // Get split stays of comp unit
    const splitStays = (await relocationService.getRelocationCaseComparableUnitSplitStays([comparableUnit.id])).data.filter(
        (splitStay: RelocationCaseComparableUnitSplitStays) => Boolean(splitStay?.attributes?.legacy_reservation_id)
    );
    if (splitStays.length === 0) return;

    let atLeastOneSplitStayMarked = false;
    let failedIds: number[] = [];
    const addFailedIds = (ids: number[]) => (failedIds = [...failedIds, ...ids]);

    for (const splitStay of splitStays) {
        try {
            await cancelAndMarkCompUnitWithResAttached(splitStay, addFailedIds);
            atLeastOneSplitStayMarked = true;
        } catch (error) {}
    }
    if (!_.isNil(callbackErrorIds)) callbackErrorIds(failedIds);
    if (atLeastOneSplitStayMarked) await markCompUnitHoldAsCancelled(comparableUnit);
};

export const getResolutions = (selectedOption: number) => {
    switch (selectedOption) {
        case WrapUpOptions.STAY_IN_HOME:
            return RelocationService.RESOLUTION_STAYED_IN_HOME;
        case WrapUpOptions.FM_NO_LONGER_NEEDED:
            return RelocationService.RESOLUTION_FM_NO_LONGER_NEEDED;
        case WrapUpOptions.CANCEL_AND_FSC:
            return RelocationService.RESOLUTION_FSC;
        case WrapUpOptions.CANCEL_AND_REFUND:
            return RelocationService.RESOLUTION_CANCELLED_REFUND;
        case WrapUpOptions.MOVE_TO_NEW_UNIT:
            return RelocationService.RESOLUTION_MOVED_TO_NEW_UNIT;
        default:
            break;
    }
};
