import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {RootState, useAppDispatch} from "../../store/store";
import {calculateHoursDaysAndMonthsToCheckin} from "../../utils";
import {CaseGuestDetails} from "../../components/single-view-case-guest-details/CaseGuestDetails";
import {CaseSubmissionDetails} from "../../components/single-view-case-submission-details/CaseSubmissionDetails";
import {CaseUnitReservation} from "../../components/single-view-case-unit-reservation/CaseUnitReservation";
import {tripProtectionTypes, NAVIGATION_TABS} from "appConstants";
import {fetchRelocationRequestReason, fetchReservationV2} from "../../store/caseProfileSlice";

// Date Management
import _ from "lodash";
import * as dayjs from "dayjs";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import {CaseAlternativeUnits} from "../../components";
import {setActiveTab} from "../../store/navigationTabSlice";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

export const CaseProfile: React.FC = () => {
  const dispatch = useAppDispatch();
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;
  const relocationRequest = useSelector((state: RootState) => state.caseProfile.relocationRequest) as any;
  const relocationRequestReason = useSelector((state: RootState) => state.caseProfile.relocationRequestReason) as any;
  const reservation = useSelector((state: RootState) => state.caseProfile.reservation) as any;
  const reservationV2 = useSelector((state: RootState) => state.caseProfile.reservationV2) as any;
  const unit = useSelector((state: RootState) => state.caseProfile.unit) as any;

  useEffect(() => {
    dispatch(fetchReservationV2(reservation.attributes.uuid));
    dispatch(fetchRelocationRequestReason(relocationRequest?.attributes?.relocation_request_reason_id));
    dispatch(setActiveTab(NAVIGATION_TABS.INITIATION));
  }, []);

  // Guest Data
  const guest = {
    name: `${reservation.attributes?.first_name} ${reservation.attributes?.last_name}`,
    email: reservation.attributes?.email,
    phone: reservation.attributes?.phone,
    checkingInWarning: relocationCase.attributes?.priority.rank === 1 || relocationCase.attributes?.priority.rank === 2,
    checkingInTime: calculateHoursDaysAndMonthsToCheckin(relocationCase.attributes.checkin, relocationCase.attributes.timezone),
  };

  // Turnovers
  const turnoverStartDate = dayjs(relocationRequest?.attributes?.start_date).format("YYYY-MM-DD");
  const turnoverEndDate = dayjs(relocationRequest?.attributes?.end_date).format("YYYY-MM-DD");
  const turnoverExtendedDate = !_.isNil(relocationRequest?.attributes?.extended_end_date)
    ? dayjs(relocationRequest?.attributes?.extended_end_date).format("YYYY-MM-DD")
    : "N/A";

  // Finances
  const lastNight = dayjs(reservation.attributes.last_night).startOf("day");
  const firstNight = dayjs(reservation.attributes.first_night).startOf("day");
  const nights = lastNight.diff(firstNight, "days") + 1;
  const totalRent = reservation.attributes.rent;
  const totalCost = parseFloat(
    (totalRent + reservation.attributes.fee_amount + reservation.attributes.tax_amount + reservation.attributes.trip_protection_fee).toFixed(2)
  );

  // Insurance
  let insurance = "";
  if (reservationV2) {
    const addon = _.find(reservationV2?.included, {type: tripProtectionTypes.ADDON});
    if (addon?.attributes?.name === tripProtectionTypes.TRIP_PROTECTION) {
      switch (addon?.attributes?.meta?.status) {
        case "yes":
          addon?.attributes?.meta?.trip_protection_provider !== undefined
            ? (insurance = tripProtectionTypes.RED_SKY_TI)
            : (insurance = tripProtectionTypes.VACASA_TP);
          break;
        case "no_response":
          insurance = tripProtectionTypes.NO_INSURANCE;
          break;
        default:
          insurance = tripProtectionTypes.DONT_APPLY;
          break;
      }
    }
  }

  return (
    <>
      <CaseGuestDetails
        name={guest.name}
        email={guest.email}
        phone={guest.phone}
        checkingInTime={guest.checkingInTime}
        checkingInWarning={guest.checkingInWarning}
      />
      <CaseSubmissionDetails
        submittedBy={relocationRequest.attributes.created_by}
        notes={relocationRequest.attributes.reason_notes}
        reason={relocationRequestReason?.attributes?.name}
        turnover={relocationRequest.attributes.turnover}
        turnoverStartDate={turnoverStartDate}
        turnoverEndDate={turnoverEndDate}
        turnoverExtendedDate={turnoverExtendedDate}
      />
      <CaseUnitReservation
        unitId={relocationRequest.attributes.legacy_unit_id}
        unitCode={_.split(relocationCase?.attributes?.code, "-")[0]}
        unitName={unit.attributes.name}
        unitCity={unit.attributes.address.city}
        unitState={unit.attributes.address.state}
        numberOfGuest={reservation.attributes.adults + reservation.attributes.children}
        numberOfDogs={reservation.attributes.pets}
        totalRent={parseFloat(reservation.attributes.rent.toFixed(2))}
        nightlyRate={totalRent > 0 ? parseFloat((totalRent / nights).toFixed(2)) : 0.0}
        fees={parseFloat(reservation.attributes.fee_amount.toFixed(2))}
        taxes={parseFloat(reservation.attributes.tax_amount.toFixed(2))}
        insurance={insurance}
        insuranceValue={parseFloat(reservation.attributes.trip_protection_fee.toFixed(2))}
        totalCost={totalCost}
        nightlyCoverage={relocationCase.attributes.budget_nightly_coverage}
        totalCoverage={relocationCase.attributes.budget_total_coverage}
      />
      <CaseAlternativeUnits />
    </>
  );
};
