import React, {FC, useState, useEffect} from "react";
import styles from "./FileUploader.module.scss";
import {InboxOutlined, DownloadOutlined} from "@ant-design/icons";
import {Button, message, Upload, Tooltip, Empty} from "antd";
import type {UploadFile, UploadProps} from "antd/es/upload/interface";
import {useSelector} from "react-redux";
import {useAppDispatch, RootState} from "../../store/store";
import {getPresignedUrlForUploadFile, getPresignedUrlForDownloadFile, uploadFile, getUploadedFiles} from "../../store/fileUploadSlice";
import {FilesAttributesType, FileRequestType} from "@common/typing";
import {setActiveTab} from "../../store/navigationTabSlice";
import {NAVIGATION_TABS} from "appConstants";
interface FileUploaderProps {
  fileUploaderData: {
    caseId: string;
  };
}
const {Dragger} = Upload;
export const FileUploader: FC<FileUploaderProps> = (fileUploaderProps) => {
  const dispatch = useAppDispatch();
  const uploading = useSelector((state: RootState) => state.fileUploader.uploading);
  const files = useSelector((state: RootState) => state.fileUploader.files);
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [isDraggerFileListHidden, setIsDraggerFileListHidden] = useState<boolean>(false);

  useEffect(() => {
    if (files.length === 0) {
      dispatch(getUploadedFiles(relocationCase.id));
    }
    dispatch(setActiveTab(NAVIGATION_TABS.FILES));
  }, []);

  const handleUpload = async () => {
    const uploadedFiles: number[] = [];
    for (let i = 0; i < fileList.length; i++) {
      const params: FileRequestType = {
        case_id: relocationCase.id,
        filename: fileList[i].name,
      };
      const getPresignedUrlResponse = await dispatch(getPresignedUrlForUploadFile(params));
      const presignedUrlData = getPresignedUrlResponse.payload as FilesAttributesType;

      const putFileResponse = await dispatch(uploadFile({pud: presignedUrlData, file: fileList[i]}));
      const putFileData = putFileResponse.payload as Response;
      if (putFileData.ok) {
        message.success(`Upload successfully for file ${fileList[i].name}.`);
        uploadedFiles.push(i);
      } else {
        message.error(`Upload failed. Response: ${putFileData.status}`);
      }
    }

    const unUploadedFiles = fileList.filter((file, index) => !uploadedFiles.includes(index));
    setFileList(unUploadedFiles);
    dispatch(getUploadedFiles(relocationCase.id));
  };

  const handleDownload = async (file: FilesAttributesType) => {
    const params: FileRequestType = {
      case_id: relocationCase.id,
      filename: file.attributes.unique_filename,
    };
    const getPresignedUrlResponse = await dispatch(getPresignedUrlForDownloadFile(params));
    const presignedUrlData = getPresignedUrlResponse.payload as FilesAttributesType;
    window.open(presignedUrlData.attributes.url, "_blank");
  };

  const props: UploadProps = {
    name: "file",
    multiple: true,
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      if (file.size > 5242880) {
        message.error(`${file.name} file size is greater than 5 MB`);
        return false;
      }

      fileList.push(file);
      setFileList([...fileList]);
      if (!isDraggerFileListHidden) {
        // Prevent displaying drragger file list, instead we are displaying upload file list
        const uploadList = document.getElementsByClassName("ant-upload-list ant-upload-list-text");
        if (uploadList.length > 0) {
          uploadList[0].setAttribute("style", "display: none");
        }
        setIsDraggerFileListHidden(true);
      }

      return false;
    },
    fileList,
  };

  return (
    <>
      <div className={styles.reloFileUploader}>
        <div className={styles.reloFileUploaderTitle}>Attachments</div>
        <div className={styles.reloFileUploaderContainerV2}>
          <div id="draggerContainer">
            <Dragger {...props}>
              <p className="ant-upload-drag-icon">
                <InboxOutlined />
              </p>
              <p className="ant-upload-text">Click or Drag and drop here documents that you want to associate with the case</p>
              <p className="ant-upload-hint">Support for a single or bulk upload.</p>
            </Dragger>
          </div>

          <Button
            type="primary"
            onClick={handleUpload}
            disabled={fileList.length === 0}
            loading={uploading}
            style={
              fileList.length > 0
                ? {
                    background: "#0078ab",
                  }
                : {}
            }
          >
            {uploading ? "Uploading" : "Start Upload"}
          </Button>
        </div>
      </div>

      {fileList.length > 0 && (
        <div className={styles.reloFileUploader}>
          <div className={styles.reloFileUploaderTitle}>Files to Upload</div>
          <div className={styles.reloFileUploaderContainer}>
            <Upload {...props}></Upload>
          </div>
        </div>
      )}

      <div className={styles.reloFileUploader}>
        <div className={styles.reloFileUploaderTitle}>Case Files</div>
        <div className={styles.reloFileUploaderContainer}>
          <table className={styles.filesTables}>
            <thead>
              <tr>
                <th>File Name</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {files.length == 0 && <Empty description={<span>There are no files associated with this case yet.</span>} />}
              {files.map((file, i) => (
                <tr key={i}>
                  <td style={{width: "90%"}}>{file.attributes.filename.replace(/%20/g, " ")}</td>
                  <td onClick={(e: any) => handleDownload(file)} style={{width: "10%", fontSize: "20px", textAlign: "right", color: "#2C3439"}}>
                    <Tooltip placement="right" title={`Download ${file.attributes.unique_filename.replace(/%20/g, " ")}`}>
                      <DownloadOutlined style={{cursor: "pointer"}} />
                    </Tooltip>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default FileUploader;
