import React from "react";
import {Formik, Field, Form} from "formik";
import {HeaderEdit, BackButton} from "../index";
import {FormSubmissionSteps, nonUrgentOptions, requestType} from "appConstants";
import {RootState, useAppDispatch} from "../../../store/store";
import {refreshStepPath, updateFormData, updateStep} from "../../../store/formSubmissionSlice";
import {useSelector} from "react-redux";
import {NonUrgentStepSchema} from "../schemas/formSchemas";
import generalStyles from "../form-submission/FormSubmission.module.scss";
import styles from "./NonUrgentFmStep.module.scss";
import cx from "classnames";

type NonUrgentFmStepProps = {
  title: string;
  readOnly: boolean;
};

export const NonUrgentFmStep = ({title, readOnly}: NonUrgentFmStepProps) => {
  const dispatch = useAppDispatch();
  const {type} = useSelector((state: RootState) => state.formSubmission.formData);
  const typeSelected = nonUrgentOptions.find((option) => option.value === type)?.text;

  return (
    <div className={generalStyles.stepContainer}>
      <HeaderEdit title={title} readOnly={readOnly} currentStep={FormSubmissionSteps.NON_URGENT_FM} previousStep={FormSubmissionSteps.RESUME} />
      {!readOnly && (
        <p>
          <strong>NOTE:</strong>
          <span className={generalStyles.note}>
            {" "}
            Non-urgent meaning the arrival dates are not within 72 hours. Any moves within 72 hours need to be reported separately.
            {!readOnly && <span className={generalStyles.obligatory}>*</span>}
          </span>
        </p>
      )}
      <Formik
        initialValues={{
          type,
        }}
        onSubmit={(values) => {
          const currentStep = values.type === requestType.reservation.toString() ? FormSubmissionSteps.RES_ID : FormSubmissionSteps.UNIT_CODES;
          dispatch(updateFormData(values));
          dispatch(
            updateStep({
              currentStep,
              previousStep: FormSubmissionSteps.NON_URGENT_FM,
            })
          );
          dispatch(refreshStepPath({currentStep, buttonAction: "next"}));
        }}
        validationSchema={NonUrgentStepSchema}
      >
        {({errors, touched, handleChange, handleSubmit}) => (
          <Form onSubmit={handleSubmit}>
            {/* Type Of Request Section */}
            <div className={styles.nonUrgentContainer}>
              <label htmlFor="type">
                Do you know the reservation ID for this move, or do you want to enter the Unit Code and date range, so GX can pull a list of
                reservations?
              </label>
              {!readOnly ? (
                <div className={generalStyles.radioOptions} role="group" aria-labelledby="my-radio-group">
                  {nonUrgentOptions.map(({name, id, value, text}) => (
                    <div key={id} className={generalStyles.radioContainer}>
                      <Field type="radio" name={name} value={value} onChange={handleChange} />
                      <span>{text}</span>
                    </div>
                  ))}
                </div>
              ) : (
                <span className={generalStyles.response}>{typeSelected}</span>
              )}
            </div>
            {touched.type && errors.type && <p className={generalStyles.validationError}>{errors.type}</p>}

            {/* Buttons Section */}
            {!readOnly && (
              <div className={generalStyles.formButtons}>
                <BackButton step={FormSubmissionSteps.TYPE_OF_FM} previousStep={FormSubmissionSteps.NON_URGENT_FM} />
                <button type="submit" className={cx(generalStyles.stepButton, generalStyles.nextButton)}>
                  Next
                </button>
              </div>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};
