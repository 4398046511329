import {createSlice, createAsyncThunk, isAnyOf} from "@reduxjs/toolkit";
import {RelocationService} from "@common/services";
import {UnitImplicitService, ReservationImplicitService} from "@common/services";
import {defaultFilters} from "appConstants";
import {RelocationCaseAttributesType} from "@common/typing";

// Service Singletons
const relocationService = RelocationService.getInstance();
const unitService = UnitImplicitService.getInstance();
const reservationService = ReservationImplicitService.getInstance();

export const fetchRelocationCaseProfile = createAsyncThunk("caseProfile/fetchRelocationCaseProfile", async (id: number) => {
    const response = await relocationService.getRelocationCaseById(id);
    const responseRequest = await relocationService.getRelocationRequestById(response.data.attributes.relocation_request_id);
    const [responseUnit, responseReservation] = await Promise.all([
        unitService.getUnitByLegacyId(responseRequest.data.attributes.legacy_unit_id), //@ts-ignore
        relocationService.getRelocationCaseReservationById(response.data.attributes.legacy_reservation_id),
    ]);
    return {
        relocationCase: response.data,
        relocationRequest: responseRequest.data,
        unit: responseUnit,
        reservation: responseReservation.data.attributes.data,
    };
});

export const fetchRelocationCase = createAsyncThunk("caseProfile/fetchRelocationCase", async (id: number) => {
    const response = await relocationService.getRelocationCaseById(id);
    return response.data;
});

export const fetchRelocationCaseCount = createAsyncThunk("caseProfile/fetchRelocationCaseCount", async (unitCode: string) => {
    // @ts-ignore
    const response = await relocationService.getRelocationCases({unitCode, status: defaultFilters.status});
    return response.meta?.pagination?.count;
});

export const fetchReservationByLegacyId = async (id: number) => {
    return await relocationService.getRelocationCaseReservationById(id);
};

export const fetchUnitByLegacyId = async (id: number) => {
    return await unitService.getUnitByLegacyId(id);
};

export const fetchReservationV2 = createAsyncThunk("caseProfile/fetchReservationV2", async (uuid: string) => {
    return await reservationService.getReservation(uuid, true);
});

export const fetchRelocationRequestReason = createAsyncThunk("caseProfile/fetchRelocationRequestReason", async (id: number) => {
    const response = await relocationService.getRelocationRequestReasonById(id);
    return response.data;
});

export const patchRelocationCase = createAsyncThunk("caseProfile/patchRelocationCase", async (data: any) => {
    const {email} = RelocationService.getAuthenticatedUser();
    // @ts-ignore
    const response = await relocationService.updateRelocationCase(data.id, {
        updated_by: email,
        budget_nightly_coverage: data.budget_nightly_coverage,
        budget_total_coverage: data.budget_total_coverage,
    });
    return response.data;
});

export const updateSelectedCompUnitOnCase = createAsyncThunk(
    "compUnit/updateSelectedCompUnitOnCase",
    async (data: {id: number; selected: number | null}) => {
        const response = await relocationService.updateRelocationCase(data.id, {
            selected_relocation_case_comparable_unit_id: data.selected,
        } as RelocationCaseAttributesType);
        return response.data;
    }
);

export const isCaseOpen = (relocationCase: RelocationCaseAttributesType) =>
    relocationCase?.relocation_case_status_id !== RelocationService.STATUS_CANCELED &&
    relocationCase?.relocation_case_status_id !== RelocationService.STATUS_DONE;

export const isCaseResolved = (relocationCase: RelocationCaseAttributesType) =>
    relocationCase?.relocation_case_resolution_id !== RelocationService.RESOLUTION_UNRESOLVED;

const caseProfileSlice = createSlice({
    name: "caseProfile",
    initialState: {
        relocationCase: null,
        relocationRequest: null,
        relocationRequestReason: null,
        unit: null,
        unitActiveCasesCount: 0,
        reservation: null,
        reservationV2: null,
        isLoading: false,
        isLoadingCaseCount: false,
        isCaseOpen: true,
        isCaseResolved: false,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchRelocationCaseProfile.pending, (state: any, action) => {
                state.isLoading = true;
                state.relocationCase = null;
                state.relocationRequest = null;
                state.relocationRequestReason = null;
                state.reservation = null;
                state.reservationV2 = null;
                state.unit = null;
                state.unitActiveCasesCount = 0;
            })
            .addCase(fetchRelocationCaseProfile.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.relocationCase = payload.relocationCase as any;
                state.relocationRequest = payload.relocationRequest as any;
                state.unit = payload.unit as any;
                state.reservation = payload.reservation as any;
                state.isCaseOpen = isCaseOpen(payload.relocationCase.attributes);
                state.isCaseResolved = isCaseResolved(payload.relocationCase.attributes);
            })
            .addCase(fetchRelocationCaseCount.pending, (state) => {
                state.isLoadingCaseCount = true;
            })
            .addCase(fetchRelocationCaseCount.fulfilled, (state, {payload}) => {
                state.unitActiveCasesCount = payload || 0;
                state.isLoadingCaseCount = false;
            })
            .addCase(fetchRelocationRequestReason.fulfilled, (state, {payload}) => {
                state.relocationRequestReason = payload as any;
            })
            .addCase(fetchReservationV2.fulfilled, (state, {payload}) => {
                state.reservationV2 = payload as any;
            })
            .addMatcher(isAnyOf(fetchRelocationCase.fulfilled, updateSelectedCompUnitOnCase.fulfilled), (state, {payload}) => {
                state.relocationCase = payload as any;
                state.isCaseOpen = isCaseOpen(payload.attributes as any);
                state.isCaseResolved = isCaseResolved(payload.attributes as any);
            });
    },
});

export default caseProfileSlice.reducer;
