import React, {useEffect, useLayoutEffect} from "react";
import {Outlet, useLocation, useNavigate} from "react-router-dom";
import {BackSectionV2, FilterSearch, FilterActions, BulkSection, FilterUnassign, BulkSelection} from "../../components";
import {BULK_ASSIGN_ACTION, BULK_RESOLVE_ACTION, RouteList} from "appConstants";
import {useAppDispatch, RootState} from "../../store/store";
import styles from "./QueueHeader.module.scss";
import {useSelector} from "react-redux";
import {fetchMyCasesCount, fetchCaseLookups, resetFilters} from "../../store/queueSlice";
import cls from "classnames";

export const QueueHeader = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const myCasesCount = useSelector((state: RootState) => state.queue.header.casesCount);
  const myCasesCountTrigger = useSelector((state: RootState) => state.queue.triggers.fetchMyCasesCount);
  const isBulkResolveAction = useSelector((state: RootState) => state.queue.header.actions.bulkResolve.isVisible);
  const isBulkAssignAction = useSelector((state: RootState) => state.queue.header.actions.bulkAssign.isVisible);
  const isBulkAction = isBulkResolveAction || isBulkAssignAction;
  const currentIsLatamFilter = useSelector((state: RootState) => state.queue.filters.isLatam.value);

  // Active Tab Flags TODO: replace by
  const isCasesLoadRoute = location.pathname === RouteList.queue.rank;
  const isCasesMineRoute = location.pathname === RouteList.queue.mine;
  const isCasesAllRoute = location.pathname === RouteList.queue.all;

  const navigateAndReset = (path: "mine" | "rank" | "") => {
    navigate(path);
    dispatch(resetFilters());
  };

  // handlers
  const handleGoToMine = () => navigateAndReset("mine");
  const handleGoToRank = () => navigateAndReset("rank");
  const handleGoToAll = () => navigateAndReset("");

  // prefetch count and lookup data
  useLayoutEffect(() => {
    (async () => {
      await dispatch(fetchCaseLookups());
      await dispatch(fetchMyCasesCount());
    })();
  });

  // reload mys cases count
  useEffect(() => {
    dispatch(fetchMyCasesCount());
  }, [myCasesCountTrigger]);

  return (
    <>
      {isBulkAction && <BulkSelection />}
      <BackSectionV2 text="Relo Queue" />
      <div className={styles.container}>
        <div className={styles.inner}>
          {/** TITLE */}
          <div className={styles.title}>
            {isCasesAllRoute && "All Cases"}
            {isCasesMineRoute && "My Cases"}
            {isCasesLoadRoute && "Case Load"}
            {currentIsLatamFilter > 0 ? " | LATAM" : ""}
          </div>

          {/** SUBHEADER **/}
          <div className={styles.subheader}>
            {/** FILTERS */}
            <div className={styles.filters} style={{opacity: isCasesLoadRoute ? 0.3 : 1, pointerEvents: isCasesLoadRoute ? "none" : "initial"}}>
              <FilterActions isBulkAllowed={isCasesAllRoute} />
              {isBulkAction ? (
                <BulkSection mode={isBulkResolveAction ? BULK_RESOLVE_ACTION : BULK_ASSIGN_ACTION} />
              ) : (
                <>
                  <FilterSearch placeholder={"Unit or Reservation"} filterName={"unitCode"} />
                  {isCasesAllRoute && <FilterUnassign />}
                </>
              )}
            </div>

            {/** Links */}
            {!isBulkAction && (
              <div className={styles.linkContainer}>
                <a onClick={handleGoToAll} className={isCasesAllRoute ? styles.activeLink : ""}>
                  {" "}
                  All Cases{" "}
                </a>
                <a onClick={handleGoToMine} className={cls(styles.myCases, isCasesMineRoute ? styles.activeLink : "")}>
                  {myCasesCount > 0 && <div className={styles.badge}>{myCasesCount > 99 ? "99+" : myCasesCount}</div>}
                  My Cases
                </a>
                <a onClick={handleGoToRank} className={isCasesLoadRoute ? styles.activeLink : ""}>
                  {" "}
                  Case Load{" "}
                </a>
              </div>
            )}
          </div>
          <div className={styles.tableContainer}>
            <Outlet />
          </div>
        </div>
      </div>
    </>
  );
};
