import React, {FC, useState, useEffect} from "react";
import styles from "./CaseSubmissionDetails.module.scss";
import {TurnoverPeriodAttributesType} from "@common/typing";
import * as dayjs from "dayjs";
import _ from "lodash";
import {AlertComponent} from "../alert/AlertComponent";
import {Spinner} from "..";
import {RelocationService} from "@common/services";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {TurnoversTypes} from "appConstants";

const DATE_FORMAT = "YYYY-MM-DD";

interface CaseSubmissionDetailsProps {
  submittedBy: string;
  reason: string;
  notes: string;
  turnover: boolean;
  turnoverStartDate: string;
  turnoverEndDate: string;
  turnoverExtendedDate: string;
}

export const CaseSubmissionDetails: FC<CaseSubmissionDetailsProps> = ({
  submittedBy,
  reason,
  notes,
  turnover,
  turnoverStartDate,
  turnoverEndDate,
  turnoverExtendedDate,
}) => {
  const relocationRequest = useSelector((state: RootState) => state.caseProfile.relocationRequest) as any;
  const [showAlert, setShowAlert] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [alertType, setAlertType] = useState("");
  const [blockResGrid, setBlockResGrid] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const relocationService = RelocationService.getInstance();

  const validateSaveTurnover = (): boolean => turnover !== blockResGrid;

  /**
   * Saves turnover based on Relocation Request endDate/extendedEndDate
   */
  const handleSaveTurnover = async (): Promise<void> => {
    if (!validateSaveTurnover()) return;

    console.log(`Set Request (${relocationRequest.id}) Periods with Turnovers: ${blockResGrid}`);

    const endDate = dayjs(getTurnoverEndDate());

    const turnoverPeriod = {
      start_date: relocationRequest.attributes.start_date,
      end_date: endDate.format(DATE_FORMAT),
      unit_id: relocationRequest.attributes.legacy_unit_id,
      turnover: blockResGrid ? TurnoversTypes.TURNOVER_NO_CHECK_IN_OR_OUT : TurnoversTypes.TURNOVER_CHECK_IN_OR_OUT,
      request_id: relocationRequest.id,
      block_turnover: blockResGrid,
    } as TurnoverPeriodAttributesType;

    setShowSpinner(true);
    const apiResponse = await relocationService.setTurnoverPeriod(turnoverPeriod);
    processTurnoverSet(apiResponse);
  };

  /**
   * Displays Alert to User based on the Response from Aailability Service
   * @param apiResponse
   */
  const processTurnoverSet = (apiResponse: any): void => {
    const attributes = apiResponse?.data?.attributes;
    const turnoverBlock = attributes?.block_turnover;
    if (!_.isNil(turnoverBlock) && turnoverBlock === blockResGrid) {
      const verboseStartDate = dayjs(attributes?.start_date).format(DATE_FORMAT);
      const verboseEndDate = dayjs(attributes?.end_date).format(DATE_FORMAT);

      setAlertMessage(`
        Success! Turnover Period updated. From ${verboseStartDate}
        to ${verboseEndDate}.`);

      setAlertType("success");
      setShowAlert(true);
      console.log(`Turnover Period Set for Request (${relocationRequest.id})`);
    } else {
      setAlertMessage("Error! Turnover Period not updated.");
      setAlertType("error");
      setShowAlert(true);
      setBlockResGrid(turnover);
      console.log(`Turnover Period not Set for Request (${relocationRequest.id})`);
    }
    setShowSpinner(false);
  };

  /**
   * Gets Enddate to be applied over Turnover
   */
  const getTurnoverEndDate = (): string => {
    if (!_.isNil(turnoverExtendedDate) && turnoverExtendedDate !== "") {
      const endDate = dayjs(turnoverEndDate);
      const extendedEndDate = dayjs(turnoverExtendedDate);
      if (extendedEndDate.isAfter(endDate)) {
        return turnoverExtendedDate;
      }
    }
    return turnoverEndDate;
  };

  const handleBlockResGrid = (): void => {
    setBlockResGrid(!blockResGrid);
    setShowAlert(false);
  };

  useEffect(() => {
    setBlockResGrid(turnover);
  }, [turnover]);

  return (
    <div className={styles.caseSubmissionDetails}>
      <div className={styles.caseSubmissionDetailsTitle}>Submission Details</div>
      <div className={styles.caseSubmissionDetailsInfoGrid1x2}>
        <div>
          <div className={styles.caseSubmissionDetailsContentTitle}>Submitted by</div>
          <div className={styles.caseSubmissionDetailsContentText}>{submittedBy}</div>
        </div>
        <div>
          <div className={styles.caseSubmissionDetailsContentTitle}>Reason</div>
          <div className={styles.caseSubmissionDetailsContentText}>{reason}</div>
        </div>
      </div>
      <hr />

      <div className={styles.caseSubmissionDetailsInfoFlat}>
        <span className={styles.caseSubmissionDetailsContentTitle}>Notes</span>
        <div className={styles.caseSubmissionDetailsContentText}>{notes}</div>
      </div>
      <hr />

      <div className={styles.caseSubmissionDetailsInfoFlat}>
        <div className={styles.caseSubmissionDetailsSubTitle}>FM turnover block on ResGrid</div>
        <div className={styles.caseSubmissionDetailsContentText}>
          The following has been pre-selected and should most often be set to "Yes". If the unit is perfectly fine and the FM was not due to the unit
          being uninhabitable, set to "No".
        </div>
      </div>

      <div className={styles.caseSubmissionDetailsInfoGrid1x3}>
        <div>
          <div className={styles.caseSubmissionDetailsContentTitle}>Start date</div>
          <div className={styles.caseSubmissionDetailsContentText}>{turnoverStartDate}</div>
        </div>
        <div>
          <div className={styles.caseSubmissionDetailsContentTitle}>Ending date</div>
          <div className={styles.caseSubmissionDetailsContentText}>{turnoverEndDate}</div>
        </div>
        <div>
          <div className={styles.caseSubmissionDetailsContentTitle}>Extended date</div>
          <div className={styles.caseSubmissionDetailsContentText}>{turnoverExtendedDate}</div>
        </div>
      </div>

      <div className={styles.caseSubmissionDetailsInfoFlat}>
        <span className={styles.caseSubmissionDetailsContentTitle}>Block ResGrid for dates shown above?</span>
        {showAlert && <AlertComponent text={<>{alertMessage}</>} className={alertType} iconSize="25" setShowAlert={setShowAlert} />}
        <div className={styles.caseSubmissionDetailsContentText}>
          {showSpinner ? (
            <Spinner />
          ) : (
            <div>
              <div className={styles.caseSubmissionDetailsInputContainer}>
                <input
                  type="radio"
                  name="blockResGrid"
                  className={styles.caseSubmissionDetailsInput}
                  checked={blockResGrid === true}
                  onChange={handleBlockResGrid}
                />
                <span className={styles.caseSubmissionDetailsContentText}>Yes</span>
              </div>
              <div className={styles.caseSubmissionDetailsInputContainer}>
                <input
                  type="radio"
                  name="blockResGrid"
                  className={styles.caseSubmissionDetailsInput}
                  checked={blockResGrid === false}
                  onChange={handleBlockResGrid}
                />
                <span className={styles.caseSubmissionDetailsContentText}>No</span>
              </div>
              <button className={validateSaveTurnover() ? styles.button : styles.buttonDisabled} onClick={handleSaveTurnover}>
                Save
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
