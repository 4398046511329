import React, {FC, useEffect, useState} from "react";
import {HistoryItem} from "../history-item/HistoryItem";
import {VerticalTimeline, IVerticalTimelineEvent} from "../vertical-timeline/VerticalTimeline";
import styles from "./CaseHistory.module.scss";
import {Spinner} from "..";
import {RelocationCaseHistory} from "../../typing";
import {FrontendConfiguration} from "@common/configuration";
import {CaseHistoryActions, relocationPriorityDesc} from "appConstants";
import {mailToName} from "@common/utils";
import {getResolutionName, toLocalString} from "../../utils";
import {getHistoryActionNumber, getHistorySubNote, isHistoryActionWithNote, isTodayOrYesterday, getDateTimeZone} from "../../utils";
import _ from "lodash";
import {FiCalendar} from "react-icons/fi";
import {BsDash} from "react-icons/bs";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import * as advancedFormat from "dayjs/plugin/advancedFormat";
import * as dayjs from "dayjs";
import {RootState, useAppDispatch} from "../../store/store";
import {fetchRelocationCaseHistory} from "../../store/caseHistorySlice";
import {useSelector} from "react-redux";
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(advancedFormat);

interface CaseHistoryProps {}

export const CaseHistory: FC<CaseHistoryProps> = () => {
  const [parsedHistory, setParsedHistory] = useState<any>([]);

  //Redux State
  const dispatch = useAppDispatch();
  const {isLoading, relocationCaseHistory} = useSelector((state: RootState) => state.caseHistory);
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;
  const caseResolutions = useSelector((state: RootState) => state.queue.lookups.resolutions);

  /* Helpers */
  const getHistoryPriority = (priorityId: number) => {
    return relocationPriorityDesc.find((x) => x.id === priorityId)?.name;
  };

  //Gets the case history
  useEffect(() => {
    if (!_.isEmpty(relocationCase)) dispatch(fetchRelocationCaseHistory(relocationCase.id));
  }, []);

  //Parse case history to events displayed in the TimeLine
  useEffect(() => {
    if (!isLoading) setParsedHistory(parseToEvents(relocationCaseHistory));
  }, [relocationCaseHistory]);

  const getHistoryTitle = (caseHistory: RelocationCaseHistory, caseHistoryList: RelocationCaseHistory[]): JSX.Element => {
    const strongText = (txt: string) => <span className={styles.strongTitle}>{txt}</span>;
    const createdBy = mailToName(caseHistory.attributes?.created_by);
    const relocationCaseResolutionId = caseHistory.attributes?.meta?.relocation_case_resolution_id;
    const newOwnerBilledAmount = caseHistory.attributes?.meta?.owner_billing_amount ? caseHistory.attributes?.meta?.owner_billing_amount : 0;

    switch (caseHistory.attributes.relocation_case_history_action_id) {
      case CaseHistoryActions.ASSIGN:
        return caseHistory.attributes.created_by === caseHistory.attributes.value ? (
          <span>
            {strongText("Ownership")} self-assigned by {createdBy}
          </span>
        ) : (
          <span>
            {strongText("Ownership")} assigned to {mailToName(caseHistory.attributes?.value)} by {createdBy}
          </span>
        );

      case CaseHistoryActions.UNASSIGN:
        return caseHistory.attributes.created_by === caseHistory.attributes.value ? (
          <span>
            {strongText("Ownership")} surrendered by {createdBy}
          </span>
        ) : (
          <span>
            {mailToName(caseHistory.attributes?.value)} {strongText("Ownership")} removed by {createdBy}
          </span>
        );

      case CaseHistoryActions.INITIATE:
        return (
          <span>
            {strongText("Initiated")} by {createdBy}
          </span>
        );
      case CaseHistoryActions.FOLLOWUP:
        return (
          <span>
            {strongText(`Follow Up ${getHistoryActionNumber(caseHistory, caseHistoryList)}`)} recorded by {createdBy}
          </span>
        );
      case CaseHistoryActions.SUBMIT:
        return <span>{strongText("Submission")} received</span>;
      case CaseHistoryActions.COMPLETE:
        return (
          <span>
            {strongText(`Case ${caseHistory?.attributes?.meta?.bulk ? "Bulk " : ""}Completed`)} by {createdBy}{" "}
            {relocationCaseResolutionId ? `with resolution: ${getResolutionName(relocationCaseResolutionId, caseResolutions)}` : ""}
          </span>
        );
      case CaseHistoryActions.GENERAL_NOTE:
        return (
          <span>
            {strongText("Internal Note")} recorded by {createdBy}
          </span>
        );
      case CaseHistoryActions.REOPEN:
        return (
          <span>
            {strongText("Case Reopened")} by {createdBy}
          </span>
        );
      case CaseHistoryActions.PRIORITY_UPDATED:
        return (
          <span>
            {strongText("Priority Updated")} to {getHistoryPriority(parseInt(caseHistory.attributes.value))}
          </span>
        );
      case CaseHistoryActions.FOLLOWUP_UPDATED:
        return (
          <span>
            {strongText("Follow Up Update ")} to {getDateTimeZone(caseHistory.attributes.value, "YYYY-MM-DD [at] H:mmA z")} by {createdBy}
          </span>
        );
      case CaseHistoryActions.GUEST_SATISFACTION_REFUND_UPDATED:
        const refundValues = caseHistory.attributes.value.split(":");
        return (
          <span>
            {strongText("Guest satisfaction refund")} updated from {toLocalString(parseInt(refundValues[0]), "USD")} to{" "}
            {toLocalString(parseInt(refundValues[1]), "USD")} USD by {createdBy}
          </span>
        );
      case CaseHistoryActions.FINANCES_UPDATED:
        return (
          <span>
            {strongText("Finances Updated ")} by {createdBy}
          </span>
        );
      case CaseHistoryActions.OWNER_BILLING_UPDATED:
        return (
          <span>
            {strongText("Owner Billed Amount ")}to {toLocalString(newOwnerBilledAmount, "USD")}
          </span>
        );
      case CaseHistoryActions.GROUP_REMOVED:
        return <span>{strongText("Group Tag Removed")}</span>;
      default:
        return <></>;
    }
  };

  const parseToEvents = (caseHistoryList: RelocationCaseHistory[]): IVerticalTimelineEvent[] => {
    const createDateEvent = (date: string) => ({
      children: (
        <div className={styles.dateTitle}>
          {dayjs.tz(date, FrontendConfiguration.getTimeZone()).format(`MMMM D, YYYY`)}
          {isTodayOrYesterday(date) && (
            <>
              {" "}
              <BsDash /> {isTodayOrYesterday(date)}
            </>
          )}
        </div>
      ),
      customDot: <FiCalendar size={22} color={"#27221D"} />,
      clicked: true,
    });

    const groupByDate = _.groupBy(caseHistoryList, (cH) => getDateTimeZone(cH.attributes?.created_at, "YYYY-MM-DD"));

    return _.keys(groupByDate)
      .sort()
      .reverse()
      .reduce((acc, curr) => {
        return [
          ...acc,
          createDateEvent(curr),
          ..._.sortBy(groupByDate[curr], ["attributes.created_at"])
            .reverse()
            .map((cH) => ({
              children: (
                <HistoryItem
                  item={{
                    title: getHistoryTitle(cH, caseHistoryList),
                    time: getDateTimeZone(cH.attributes?.created_at, "h:mmA (z)"),
                    type: isHistoryActionWithNote(cH) ? "note" : "none",
                    note: cH.attributes?.value ?? "",
                    subNote: getHistorySubNote(cH),
                    link: "",
                  }}
                />
              ),
              clicked: true,
            })),
        ];
      }, [] as IVerticalTimelineEvent[]);
  };

  return (
    <div className={styles.box}>
      <div className={styles.title}>Case History</div>
      {isLoading ? (
        <Spinner />
      ) : (
        <div>
          <VerticalTimeline events={parsedHistory} />
        </div>
      )}
    </div>
  );
};
