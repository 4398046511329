import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {EventService, ImplicitFlowService, RelocationService} from "@common/services";
import {defaultFilters, DEFAULT_COUNT_MY_CASES} from "appConstants";
import {RootState} from "./store";
import dayjs from "dayjs";
import {EventSources, EventTypes} from "@common/utils";
import {datadogLogs} from "@datadog/browser-logs";

interface QueryFilters {
    pageNumber: number;
    pageSize: number;
    unitCode: string;
    assignedTo: string;
    status: string;
    priority: any;
    group: string;
    unassigned: number;
    createdAt: string;
    isLatam: number;
    task: string;
}

interface PostCasesAssignmentData {
    relocationCaseId: number;
    assignedTo: string;
}

interface PostBulkCaseResolveData {
    relocationCaseIds: Array<number>;
    wrapupNote: string;
}

interface PostBulkCaseAssignmentData {
    relocationCaseIds: Array<number>;
    assignedTo: string;
}

const getStateBasedFilters = (state: RootState) => ({
    ...defaultFilters,

    // state based filters
    priority: state.queue.filters.priority.value,
    createdAt: state.queue.filters.createdAt.value,
    group: state.queue.filters.group.value,
    status: state.queue.filters.status.value,
    unitCode: state.queue.filters.unitCode.value,
    unassigned: state.queue.filters.unassigned.value,
    assignedTo: state.queue.filters.assignedTo.value,
    isLatam: state.queue.filters.isLatam.value,
    task: state.queue.filters.task.value,

    // pagination
    pageNumber: state.queue.filters.pagination.number,
    pageSize: state.queue.filters.pagination.size,
});

export const fetchMyCasesCount = createAsyncThunk("queue/fetchMyCasesCount", async () => {
    const relocationService = RelocationService.getInstance();
    const {email} = ImplicitFlowService.getAuthenticatedUser();
    const response = await relocationService.getRelocationCases({
        ...defaultFilters,
        group: DEFAULT_COUNT_MY_CASES.group,
        assignedTo: email,
        isLatam: DEFAULT_COUNT_MY_CASES.allCases,
    });
    return response.meta?.pagination.count;
});

export const fetchCases = createAsyncThunk("queue/fetchCases", async (filters, {getState}) => {
    const state = getState() as RootState;
    const response = RelocationService.getInstance().getRelocationCases({
        ...getStateBasedFilters(state),
    });
    return response;
});

export const fetchMyCases = createAsyncThunk("queue/fetchMyCases", async (filters, {getState}) => {
    const state = getState() as RootState;
    const {email} = ImplicitFlowService.getAuthenticatedUser();
    const response = await RelocationService.getInstance().getRelocationCases({
        ...getStateBasedFilters(state),
        assignedTo: email,
    });
    return response;
});

export const fetchCasesRank = createAsyncThunk("queue/fetchCasesRank", async () => {
    const relocationService = RelocationService.getInstance();
    const response = await relocationService.getRelocationCasesRanking();
    return response.data;
});

export const fetchCaseLookups = createAsyncThunk("queue/fetchCaseLookups", async () => {
    const relocationService = RelocationService.getInstance();
    const resolutionsResponse = await relocationService.getRelocationCaseResolutions();
    const tasksResponse = await relocationService.getRelocationCaseTasks();
    return {
        resolutions: resolutionsResponse.data,
        tasks: tasksResponse.data,
    };
});

export const postCaseAssignment = createAsyncThunk("queue/postCaseAssignment", async (data: PostCasesAssignmentData) => {
    const relocationService = RelocationService.getInstance();
    const {assignedTo, relocationCaseId} = data;
    const assignedToDatetime = dayjs().toISOString();
    const {email: createdBy} = RelocationService.getAuthenticatedUser();

    // @ts-ignore
    const relocationCase = await relocationService.updateRelocationCase(relocationCaseId, {
        assigned_to: assignedTo,
        assigned_to_datetime: assignedToDatetime,
    });

    EventService.dispatch(datadogLogs, {
        title: "Case Assigned Success",
        message: `Case id: ${relocationCaseId} Assigned to: ${assignedTo} `,
        type: EventTypes.CASE_ASSIGNED,
        source: EventSources.UI,
        level: EventService.INFO_LEVEL,
        data: relocationCase,
    });

    // @ts-ignore
    await relocationService.createRelocationCaseHistory({
        relocation_case_id: relocationCaseId,
        relocation_case_history_action_id: RelocationService.HISTORY_ACTION_ASSING,
        value: assignedTo,
        created_by: createdBy,
    });
});

export const postCaseUnassingment = createAsyncThunk("queue/postCaseUnassignment", async (data: {relocationCaseId: number}) => {
    const relocationService = RelocationService.getInstance();
    const assignedToDatetime = dayjs().toISOString();
    const {email: createdBy} = RelocationService.getAuthenticatedUser();
    const {relocationCaseId} = data;
    // @ts-ignore
    const relocationCase = await relocationService.updateRelocationCase(relocationCaseId, {
        assigned_to: "",
        assigned_to_datetime: assignedToDatetime,
        updated_by: createdBy,
    });

    EventService.dispatch(datadogLogs, {
        title: "Case Unassigned Success",
        message: `Case id: ${relocationCaseId} Unassigned Success `,
        type: EventTypes.CASE_UNASSIGNED,
        source: EventSources.UI,
        level: EventService.INFO_LEVEL,
        data: relocationCase,
    });

    // @ts-ignore
    await relocationService.createRelocationCaseHistory({
        relocation_case_id: relocationCaseId,
        relocation_case_history_action_id: RelocationService.HISTORY_ACTION_UNASSING,
        created_by: createdBy,
        created_at: assignedToDatetime,
    });
});

export const postCaseBulkResolve = createAsyncThunk("queue/postCaseBulkResolve", async (data: PostBulkCaseResolveData) => {
    const relocationService = RelocationService.getInstance();
    const assignedToDatetime = dayjs().toISOString();
    const {email: createdBy} = RelocationService.getAuthenticatedUser();
    const {relocationCaseIds, wrapupNote} = data;

    for (const relocationCaseId of relocationCaseIds) {
        // @ts-ignore
        const relocationCase = await relocationService.updateRelocationCase(relocationCaseId, {
            relocation_case_status_id: RelocationService.STATUS_DONE,
            relocation_case_resolution_id: RelocationService.RESOLUTION_FM_NO_LONGER_NEEDED,
            wrapup_note: wrapupNote,
            updated_by: createdBy,
            assigned_to: createdBy,
            assigned_to_datetime: assignedToDatetime,
        });

        EventService.dispatch(datadogLogs, {
            title: "Case Assigned Success",
            message: `Case id: ${relocationCaseId} Assigned to: ${createdBy} `,
            type: EventTypes.CASE_ASSIGNED,
            source: EventSources.UI,
            level: EventService.INFO_LEVEL,
            data: relocationCase,
        });

        // @ts-ignore
        await relocationService.createRelocationCaseHistory({
            relocation_case_id: relocationCaseId,
            relocation_case_history_action_id: RelocationService.HISTORY_ACTION_COMPLETE,
            value: createdBy,
            created_by: createdBy,
            meta: {
                email: false,
                text: false,
                phone: false,
                bulk: true,
            },
        });
    }
});

export const postCaseBulkAssignment = createAsyncThunk("queue/postCaseBulkAssignment", async (data: PostBulkCaseAssignmentData) => {
    const relocationService = RelocationService.getInstance();
    const assignedToDatetime = dayjs().toISOString();
    const {email: createdBy} = RelocationService.getAuthenticatedUser();
    const {relocationCaseIds, assignedTo} = data;

    for (const relocationCaseId of relocationCaseIds) {
        // @ts-ignore
        const relocationCase = await relocationService.updateRelocationCase(relocationCaseId, {
            assigned_to: assignedTo,
            assigned_to_datetime: assignedToDatetime,
        });

        EventService.dispatch(datadogLogs, {
            title: "Case Assigned Success",
            message: `Case id: ${relocationCaseId} Assigned to: ${assignedTo} `,
            type: EventTypes.CASE_ASSIGNED,
            source: EventSources.UI,
            level: EventService.INFO_LEVEL,
            data: relocationCase,
        });

        // @ts-ignore
        await relocationService.createRelocationCaseHistory({
            relocation_case_id: relocationCaseId,
            relocation_case_history_action_id: RelocationService.HISTORY_ACTION_ASSING,
            value: assignedTo,
            created_by: createdBy,
        });
    }
});

const initialState = {
    header: {
        casesCount: 0,
        isLoading: false,
        actions: {
            bulkResolve: {
                isVisible: false,
            },
            bulkAssign: {
                isVisible: false,
            },
        },
    },
    lookups: {
        resolutions: [],
        tasks: [],
    },
    triggers: {
        fetchMyCasesCount: 0,
        clearFilters: 0,
    },
    filters: {
        unassigned: {
            value: defaultFilters.unassigned,
        },
        priority: {
            isVisible: false,
            value: defaultFilters.priority,
        },
        createdAt: {
            isVisible: false,
            value: defaultFilters.createdAt,
        },
        assignedTo: {
            isVisible: false,
            value: defaultFilters.assignedTo,
        },
        task: {
            isVisible: false,
            value: defaultFilters.task,
        },
        status: {
            value: defaultFilters.status,
        },
        group: {
            value: defaultFilters.group,
        },
        casesRank: {
            list: [],
            isLoading: false,
        },
        unitCode: {
            value: defaultFilters.unitCode,
        },
        pagination: {
            count: 0,
            number: defaultFilters.pageNumber,
            size: defaultFilters.pageSize,
        },
        isLatam: {
            value: defaultFilters.isLatam,
        },
    },
    casesRank: {
        list: [],
        isLoading: false,
    },
    casesMine: {
        list: [],
        isLoading: false,
        isEmpty: false,
    },
    casesAll: {
        list: [],
        isLoading: false,
        isEmpty: false,
    },
};

const queueSlice = createSlice({
    name: "queue",
    initialState,
    reducers: {
        toggleBulkResolveAction: (state) => {
            state.header.actions.bulkAssign.isVisible = false;
            state.header.actions.bulkResolve.isVisible = !state.header.actions.bulkResolve.isVisible;
            state.filters.status.value = initialState.filters.status.value;
        },
        toggleBulkAssignAction: (state) => {
            state.header.actions.bulkResolve.isVisible = false;
            state.header.actions.bulkAssign.isVisible = !state.header.actions.bulkAssign.isVisible;
            state.filters.status.value = initialState.filters.status.value;
        },
        togglePriorityFilter: (state) => {
            state.filters.priority.isVisible = !state.filters.priority.isVisible;
        },
        toggleCreatedAtFilter: (state) => {
            state.filters.createdAt.isVisible = !state.filters.createdAt.isVisible;
        },
        toggleAssignedToFilter: (state) => {
            state.filters.assignedTo.isVisible = !state.filters.assignedTo.isVisible;
        },
        toggleTaskFilter: (state) => {
            state.filters.task.isVisible = !state.filters.task.isVisible;
        },
        updatePriorityFilter: (state, {type, payload}: {type: string; payload: any}) => {
            state.filters.priority.value = payload;
        },
        updateCreatedAtFilter: (state, {type, payload}: {type: string; payload: string}) => {
            state.filters.createdAt.value = payload as any;
        },
        updateStatusFilter: (state, {type, payload}: {type: string; payload: string}) => {
            state.filters.status.value = payload;
        },
        updateUnassignedFilter: (state, {type, payload}) => {
            state.filters.unassigned.value = payload as any;
        },
        updateAssignedToFilter: (state, {type, payload}: {type: string; payload: string}) => {
            state.filters.assignedTo.value = payload;
        },
        updateTaskFilter: (state, {type, payload}: {type: string; payload: string}) => {
            state.filters.task.value = payload as any;
        },
        updateCodeFilter: (state, {type, payload}: {type: string; payload: string}) => {
            state.filters.unitCode.value = payload;
        },
        updateFilter: (state, {type, payload}) => {
            state.filters.status.value = payload.status;
            state.filters.group.value = payload.group;
            state.header.actions.bulkResolve.isVisible = false;
            state.header.actions.bulkAssign.isVisible = false;
            state.filters.isLatam.value = payload.isLatam;
        },
        updateQueryFilters: (state, {type, payload}: {type: string; payload: QueryFilters}) => {
            state.filters.pagination.number = payload.pageNumber;
            state.filters.pagination.size = payload.pageSize;
            state.filters.assignedTo.value = payload.assignedTo;
            state.filters.unitCode.value = payload.unitCode;
            state.filters.group.value = payload.group;
            state.filters.status.value = payload.status;
            state.filters.priority.value = payload.priority;
            state.filters.unassigned.value = payload.unassigned;
            state.filters.createdAt.value = payload.createdAt;
            state.filters.isLatam.value = payload.isLatam;
            state.filters.task.value = payload.task;
        },
        updatePaginationFilter: (state, {type, payload}) => {
            state.filters.pagination.number = payload.pageNumber;
            if (payload.pageSize) {
                state.filters.pagination.size = payload.pageSize;
            }
        },
        resetFilters: (state) => {
            state.filters = {
                ...initialState.filters,
            };
            state.header.actions = {
                ...initialState.header.actions,
            };
            state.triggers = {
                ...initialState.triggers,
                clearFilters: state.triggers.clearFilters + 1,
            };
        },
        emitFetchMyCasesCount: (state) => {
            state.triggers.fetchMyCasesCount = state.triggers.fetchMyCasesCount + 1;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchMyCasesCount.pending, (state) => {
                state.header.isLoading = true;
            })
            .addCase(fetchMyCasesCount.fulfilled, (state, {payload}) => {
                state.header.isLoading = true;
                state.header.casesCount = payload || 0;
            })
            .addCase(fetchCasesRank.pending, (state, action) => {
                state.casesRank.isLoading = true;
            })
            .addCase(fetchCasesRank.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.casesRank.list = payload;
                state.casesRank.isLoading = false;
            })
            .addCase(fetchMyCases.pending, (state, action) => {
                state.casesMine.isLoading = true;
            })
            .addCase(fetchMyCases.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.casesMine.list = payload.data;
                state.casesMine.isLoading = false;
                state.casesMine.isEmpty = payload.data.length === 0;

                // @ts-ignore
                state.filters.pagination.count = payload?.meta?.pagination.count;
                // @ts-ignore
                state.filters.pagination.number = payload?.meta?.pagination.page;
            })
            .addCase(fetchCases.pending, (state, action) => {
                state.casesAll.isLoading = true;
            })
            .addCase(fetchCases.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.casesAll.list = payload.data;
                state.casesAll.isLoading = false;
                state.casesAll.isEmpty = payload.data.length === 0;

                // @ts-ignore
                state.filters.pagination.count = payload?.meta?.pagination.count;
                // @ts-ignore
                state.filters.pagination.number = payload?.meta?.pagination.page;
            })
            .addCase(fetchCaseLookups.pending, (state, action) => {})
            .addCase(fetchCaseLookups.fulfilled, (state, {payload}) => {
                state.lookups.resolutions = payload.resolutions as any;
                state.lookups.tasks = payload.tasks as any;
            });
    },
});

export const {
    togglePriorityFilter,
    toggleCreatedAtFilter,
    toggleTaskFilter,
    toggleAssignedToFilter,
    toggleBulkAssignAction,
    toggleBulkResolveAction,
    updateAssignedToFilter,
    updateUnassignedFilter,
    updatePriorityFilter,
    updateCreatedAtFilter,
    updateTaskFilter,
    updateStatusFilter,
    updateCodeFilter,
    updateFilter,
    updatePaginationFilter,
    updateQueryFilters,
    resetFilters,
    emitFetchMyCasesCount,
} = queueSlice.actions;

export default queueSlice.reducer;
