import React, {FC, useEffect, useState, useLayoutEffect} from "react";
import {useSelector} from "react-redux";
import {fetchHolidays, addHoliday, deleteHoliday, showAddHolidayModal, hideAddHolidayModal, HolidaysSliceState} from "../../store/holidaysSlice";
import {Table, Modal, Button, Form, Input, Col, Row, message, DatePicker} from "antd";
import {HolidaysType, HolidaysAttributesType} from "@common/typing";
import {Tooltip} from "@vacasa/react-components-lib";
import {BsTrash} from "react-icons/bs";
import {ADMIN_USERS} from "appConstants";
import {useAppDispatch, RootState} from "../../store/store";
import styles from "./Holidays.module.scss";
import {ImplicitFlowService} from "@common/services";

export const Holidays: FC = () => {
  // state
  const dispatch = useAppDispatch();
  const holidays = useSelector((state: RootState) => state.holidays.list);
  const isLoading = useSelector((state: RootState) => state.holidays.isLoading);
  const isLoadingAddHoliday = useSelector((state: RootState) => state.holidays.isLoadingAddHoliday);
  const isModalVisible = useSelector((state: RootState) => state.holidays.isModalVisible);
  const {email} = ImplicitFlowService.getAuthenticatedUser();

  // form references
  const [form] = Form.useForm();
  const [tableDataSource, setTableDataSource] = useState<HolidaysType[]>();
  const {RangePicker} = DatePicker;

  useEffect(() => {
    dispatch(fetchHolidays());
  }, []);

  // Validate User
  useLayoutEffect(() => {
    if (ADMIN_USERS.includes(email)) return;
    window.location.href = `${window.location.origin}/`;
  }, []);

  // Set Table Data Source
  useEffect(() => {
    if (!holidays?.length) return;
    const tableData = holidays.map((holiday: HolidaysAttributesType) => {
      return {
        id: holiday.id,
        name: holiday.attributes.name,
        dateRange: `${holiday.attributes.start_date} to ${holiday.attributes.end_date}`,
        repeatAnnually: "Y/N",
        type: holiday.type,
      };
    });
    // @ts-ignore
    setTableDataSource(tableData);
  }, [holidays]);

  // Column Styling
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "45%",
    },
    {
      title: "Date Range",
      dataIndex: "dateRange",
      key: "dateRange",
      width: "45%",
    },
    {
      title: "Action",
      key: "action",
      render: (text: string, holiday: HolidaysType) => (
        <Tooltip message="delete">
          <Button type="link" onClick={() => handleDelete(holiday)}>
            <BsTrash className={styles.icon} size="22px" />
          </Button>
        </Tooltip>
      ),
      width: "10%",
    },
  ];

  // drop holiday && refresh
  const handleDelete = async (holiday: HolidaysType) => {
    try {
      await dispatch(deleteHoliday(holiday.id || 0));
      message.success("Holiday Deleted!");
      dispatch(fetchHolidays());
    } catch (error) {
      message.error("Holiday Deletion Failed!");
    }
  };

  // add holiday && refresh
  const handleAddHoliday = async () => {
    try {
      const {name, date_range} = await form.validateFields();
      await dispatch(
        addHoliday({
          name,
          start_date: date_range[0],
          end_date: date_range[1],
          created_by: email,
        })
      );
      message.success("New Holiday Created!");
      form.resetFields();
      dispatch(fetchHolidays());
    } catch (error) {
      message.error("Holiday Creation Failed!");
      console.log("Failed:", error);
    }
  };

  return (
    <>
      <Row>
        <Col span={4} offset={21}>
          <Button type="primary" className={styles.buttonAdd} onClick={() => dispatch(showAddHolidayModal())}>
            Add Holiday
          </Button>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <div style={{padding: "10px 30px"}}>
            <Table columns={columns} dataSource={tableDataSource} rowKey="id" size="small" loading={isLoading} />
          </div>
        </Col>
      </Row>

      <Modal
        open={isModalVisible}
        onCancel={() => dispatch(hideAddHolidayModal())}
        width={400}
        footer={[
          <Button key="clear" onClick={() => form.resetFields()} className={styles.btnClear}>
            Clear
          </Button>,
          <Button key="button" type="primary" loading={isLoadingAddHoliday} onClick={handleAddHoliday} className={styles.btnSave}>
            Save
          </Button>,
        ]}
      >
        <h2>Add New Holiday Date Range</h2>
        <Form form={form} layout="vertical" initialValues={{}}>
          <Form.Item label={"Holiday Name"} name="name" rules={[{required: true, message: "Please input holiday name"}]}>
            <Input />
          </Form.Item>
          <Row>
            <Col span={24}>
              <Form.Item
                label={"Date Range"}
                name="date_range"
                rules={[{type: "array" as const, required: true, message: "Please select holiday range"}]}
              >
                <RangePicker format={"yyyy/MM/DD"} className={styles.rangePicker} />
              </Form.Item>
            </Col>
          </Row>
        </Form>
      </Modal>
    </>
  );
};
