import {createSlice, createAsyncThunk, isAnyOf} from "@reduxjs/toolkit";
import {RelocationService} from "@common/services";
import _ from "lodash";
import {RelocationCaseComparableUnitAttributesType, RelocationCaseComparableUnitSplitStaysAttributesType} from "@common/typing";
import {RelocationCaseComparableUnitType, RelocationCaseComparableUnit, RelocationCaseComparableUnitSplitStays} from "../typing";
import {HOMES_UNIT_OPTION_LIMIT, OptionsAvailable, OTHER_UNIT_OPTION_LIMIT} from "appConstants";
import {datadogLogs} from "@datadog/browser-logs";

// Service Singletons
const relocationService = RelocationService.getInstance();

export const fetchCompUnits = createAsyncThunk("compUnit/fetchCompUnits", async (id: number) => {
    const relocationCaseComparableUnitTypes = (await relocationService.getRelocationCaseComparableUnitTypes()).data;
    const relocationCaseComparableUnits = (await relocationService.getRelocationCaseComparableUnits(id)).data;
    let relocationCaseComparableUnitSplitStays = [] as any;
    if (!_.isNil(relocationCaseComparableUnits) && relocationCaseComparableUnits.length > 0) {
        const compUnitIds = relocationCaseComparableUnits.map((e: any) => e.id);
        relocationCaseComparableUnitSplitStays = (await relocationService.getRelocationCaseComparableUnitSplitStays(compUnitIds)).data;
    }
    return {
        relocationCaseComparableUnitTypes: relocationCaseComparableUnitTypes,
        relocationCaseComparableUnits: relocationCaseComparableUnits,
        relocationCaseComparableUnitSplitStays: relocationCaseComparableUnitSplitStays,
    };
});

export const fetchComparableUnit = async (id: number) => {
    try {
        return (await relocationService.getRelocationCaseComparableUnitById(id)).data;
    } catch (error) {
        datadogLogs.logger.error(error as any);
    }
};

export const fetchSplitStay = async (ids: number[]) => {
    try {
        return (await relocationService.getRelocationCaseComparableUnitSplitStays(ids)).data;
    } catch (error) {
        datadogLogs.logger.error(error as any);
    }
};

export const createCompUnit = createAsyncThunk("compUnit/createCompUnit", async (data: RelocationCaseComparableUnitAttributesType) => {
    const response = await relocationService.createRelocationCaseComparableUnit(data);
    return response.data;
});

export const updateCompUnit = createAsyncThunk(
    "compUnit/updateCompUnit",
    async (data: {id: number; data: RelocationCaseComparableUnitAttributesType}) => {
        const response = await relocationService.updateRelocationCaseComparableUnit(data.id, data.data);
        return response.data;
    }
);

export const deleteCompUnit = createAsyncThunk("compUnit/deleteCompUnit", async (id: number) => {
    await relocationService.deleteRelocationCaseComparableUnit(id);
    return id;
});

export const createSplitStay = createAsyncThunk("compUnit/createSplitStay", async (data: RelocationCaseComparableUnitSplitStaysAttributesType) => {
    const response = await relocationService.createRelocationCaseComparableUnitSplitStays(data);
    return response.data;
});

export const updateSplitStay = createAsyncThunk(
    "compUnit/updateSplitStay",
    async (data: {id: number; data: RelocationCaseComparableUnitSplitStaysAttributesType}) => {
        const response = await relocationService.updateRelocationCaseComparableUnitSplitStays(data.id, data.data);
        return response.data;
    }
);

export const deleteSplitStay = createAsyncThunk("compUnit/deleteSplitStay", async (id: number) => {
    return await relocationService.deleteRelocationCaseComparableUnitSplitStays(id);
});

export const createCompUnitWithSplitStays = createAsyncThunk(
    "compUnit/createCompUnitWithSplitStays",
    async (data: {compUnit: RelocationCaseComparableUnitAttributesType; splitStays: RelocationCaseComparableUnitSplitStaysAttributesType[]}) => {
        const compUnitCreated = await relocationService.createRelocationCaseComparableUnit(data.compUnit);
        const splitStaysToCreate = data.splitStays.map((e) => ({...e, relocation_case_comparable_unit_id: compUnitCreated.data.id}));
        let splitStaysCreated = [];
        for (let i = 0; i < splitStaysToCreate.length; i++) {
            const created = await relocationService.createRelocationCaseComparableUnitSplitStays(splitStaysToCreate[i]);
            splitStaysCreated.push(created.data);
        }
        return {
            compUnit: compUnitCreated.data,
            splitStays: splitStaysCreated,
        };
    }
);

export const updateCompUnitWithSplitStay = createAsyncThunk(
    "compUnit/updateCompUnitWithSplitStay",
    async (data: {
        id: number;
        compUnit: RelocationCaseComparableUnitAttributesType;
        splitStays: {
            create: RelocationCaseComparableUnitSplitStaysAttributesType[];
            update: RelocationCaseComparableUnitSplitStaysAttributesType[];
            delete: RelocationCaseComparableUnitSplitStaysAttributesType[];
        };
    }) => {
        const compUnitUpdated = await relocationService.updateRelocationCaseComparableUnit(data.id, data.compUnit);
        if (!_.isEmpty(data.splitStays)) {
            let splitStaysCreated = [],
                splitStaysUpdated = [],
                splitStaysDeleted: any[] = [];
            if (data.splitStays.create) {
                const toCreate = data.splitStays.create.map((e) => ({...e, relocation_case_comparable_unit_id: data.id}));
                for (let i = 0; i < toCreate.length; i++) {
                    const created = await relocationService.createRelocationCaseComparableUnitSplitStays(toCreate[i]);
                    splitStaysCreated.push(created.data);
                }
            }
            if (data.splitStays.update) {
                const toUpdate = data.splitStays.update.map((e) => ({...e, relocation_case_comparable_unit_id: data.id}));
                for (let i = 0; i < toUpdate.length; i++) {
                    const temp = {...toUpdate[i]};
                    const id = temp.id;
                    delete temp.id;
                    if (id) {
                        const splitUpdated = await relocationService.updateRelocationCaseComparableUnitSplitStays(id, temp);
                        splitStaysUpdated.push(splitUpdated.data);
                    }
                }
            }
            if (data.splitStays.delete) {
                for (let i = 0; i < data.splitStays.delete.length; i++) {
                    let id = data.splitStays.delete[i]?.id;
                    if (id) await relocationService.deleteRelocationCaseComparableUnitSplitStays(id);
                }
                splitStaysDeleted = data.splitStays.delete.map((_) => _.id);
            }
            return {
                compUnit: compUnitUpdated.data,
                splitStays: {
                    created: splitStaysCreated,
                    updated: splitStaysUpdated,
                    deleted: splitStaysDeleted,
                },
            };
        }
    }
);

export const deleteCompUnitWithSplitStay = createAsyncThunk(
    "compUnit/deleteCompUnitWithSplitStay",
    async (data: {id: number; splitStays: number[]}) => {
        for (const id of data.splitStays) {
            await relocationService.deleteRelocationCaseComparableUnitSplitStays(id);
        }
        await relocationService.deleteRelocationCaseComparableUnit(data.id);
        return {
            id: data.id,
            splitStays: data.splitStays,
        };
    }
);

type initialStateType = {
    relocationCaseComparableUnitTypes: RelocationCaseComparableUnitType[];
    relocationCaseComparableUnits: RelocationCaseComparableUnit[];
    relocationCaseComparableUnitSplitStays: RelocationCaseComparableUnitSplitStays[];
    isLoading: boolean;
    showUnitCountAlert: boolean;
    showInactiveUnitOptionSelected: boolean;
    restrictions: {
        moreHomesOptionsDisabled: boolean;
        moreOtherOptionsDisabled: boolean;
        moreUnitOptionsDisabled: boolean;
    };
};

const initialState: initialStateType = {
    relocationCaseComparableUnitTypes: [],
    relocationCaseComparableUnits: [],
    relocationCaseComparableUnitSplitStays: [],
    isLoading: false,
    showUnitCountAlert: false,
    showInactiveUnitOptionSelected: false,
    restrictions: {
        moreHomesOptionsDisabled: false,
        moreOtherOptionsDisabled: false,
        moreUnitOptionsDisabled: false,
    },
};

const compUnitSlice = createSlice({
    name: "compUnit",
    initialState,
    reducers: {
        showUnitCountAlert: (state) => {
            state.showUnitCountAlert = true;
        },
        hideUnitCountAlert: (state) => {
            state.showUnitCountAlert = false;
        },
        hideUnitAvailableAlert: (state) => {
            state.showInactiveUnitOptionSelected = false;
        },
        showUnitAvailableAlert: (state) => {
            state.showInactiveUnitOptionSelected = true;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCompUnits.pending, (state: any, action) => {
                state.isLoading = true;
            })
            .addCase(fetchCompUnits.fulfilled, (state, {payload}) => {
                state.isLoading = false;
                state.relocationCaseComparableUnitTypes = payload.relocationCaseComparableUnitTypes as any;
                state.relocationCaseComparableUnits = payload.relocationCaseComparableUnits as any;
                state.relocationCaseComparableUnitSplitStays = payload.relocationCaseComparableUnitSplitStays as any;
            })
            .addCase(createCompUnit.fulfilled, (state, {payload}) => {
                state.relocationCaseComparableUnits = [...state.relocationCaseComparableUnits, payload] as any;
            })
            .addCase(deleteCompUnit.fulfilled, (state, {payload}) => {
                state.relocationCaseComparableUnits.splice(_.findIndex(state.relocationCaseComparableUnits, {id: payload} as any), 1);
            })
            .addCase(updateCompUnit.fulfilled, (state, {payload}) => {
                // @ts-ignore
                state.relocationCaseComparableUnits.splice(_.findIndex(state.relocationCaseComparableUnits, {id: payload.id} as any), 1, payload);
            })
            .addCase(createCompUnitWithSplitStays.fulfilled, (state, {payload}) => {
                state.relocationCaseComparableUnits = [...state.relocationCaseComparableUnits, payload.compUnit] as any;
                state.relocationCaseComparableUnitSplitStays = [...state.relocationCaseComparableUnitSplitStays, ...payload.splitStays] as any;
            })
            .addCase(deleteCompUnitWithSplitStay.fulfilled, (state, {payload}) => {
                state.relocationCaseComparableUnits.splice(_.findIndex(state.relocationCaseComparableUnits, {id: payload.id} as any), 1);
                for (const id of payload.splitStays) {
                    state.relocationCaseComparableUnitSplitStays.splice(
                        _.findIndex(state.relocationCaseComparableUnitSplitStays, {id: id} as any),
                        1
                    );
                }
            })
            .addCase(updateCompUnitWithSplitStay.fulfilled, (state, {payload}: {payload: any}) => {
                if (payload) {
                    state.relocationCaseComparableUnits.splice(
                        _.findIndex(state.relocationCaseComparableUnits, {id: payload.compUnit.id} as any),
                        1,
                        payload.compUnit as never
                    );
                    if (!_.isNil(payload) && !_.isEmpty(payload.splitStays.created)) {
                        state.relocationCaseComparableUnitSplitStays = [
                            ...state.relocationCaseComparableUnitSplitStays,
                            ...payload.splitStays.created,
                        ] as any;
                    }
                    if (!_.isNil(payload) && !_.isEmpty(payload.splitStays.updated)) {
                        for (const updated of payload.splitStays.updated) {
                            state.relocationCaseComparableUnitSplitStays.splice(
                                _.findIndex(state.relocationCaseComparableUnitSplitStays, {id: updated.id} as any),
                                1,
                                updated as never
                            );
                        }
                    }
                    if (!_.isNil(payload) && !_.isEmpty(payload.splitStays.deleted)) {
                        for (const id of payload.splitStays.deleted) {
                            state.relocationCaseComparableUnitSplitStays.splice(
                                _.findIndex(state.relocationCaseComparableUnitSplitStays, {id: id} as any),
                                1
                            );
                        }
                    }
                }
            })
            .addMatcher(
                isAnyOf(
                    fetchCompUnits.fulfilled,
                    createCompUnit.fulfilled,
                    deleteCompUnit.fulfilled,
                    createCompUnitWithSplitStays.fulfilled,
                    deleteCompUnitWithSplitStay.fulfilled
                ),
                (state) => {
                    state.restrictions.moreHomesOptionsDisabled =
                        state.relocationCaseComparableUnits.filter(
                            (e: any) => e.attributes.relocation_case_comparable_unit_type_id === OptionsAvailable.HOMES_AVAILABLE
                        ).length >= HOMES_UNIT_OPTION_LIMIT;
                    state.restrictions.moreOtherOptionsDisabled =
                        state.relocationCaseComparableUnits.filter(
                            (e: any) => e.attributes.relocation_case_comparable_unit_type_id !== OptionsAvailable.HOMES_AVAILABLE
                        ).length >= OTHER_UNIT_OPTION_LIMIT;
                    const noHomesAvailable =
                        state.relocationCaseComparableUnits.filter(
                            (e: any) => e.attributes.relocation_case_comparable_unit_type_id === OptionsAvailable.NO_HOMES_AVAILABLE
                        ).length > 0;
                    state.restrictions.moreUnitOptionsDisabled =
                        (state.restrictions.moreHomesOptionsDisabled && state.restrictions.moreOtherOptionsDisabled) || noHomesAvailable;
                    state.showUnitCountAlert = state.restrictions.moreOtherOptionsDisabled || state.restrictions.moreHomesOptionsDisabled;
                }
            );
    },
});

export const {showUnitCountAlert, hideUnitCountAlert, showUnitAvailableAlert, hideUnitAvailableAlert} = compUnitSlice.actions;

export default compUnitSlice.reducer;
