import React, {useEffect} from "react";
import {useSelector} from "react-redux";
import {useParams} from "react-router-dom";
import {SingleCaseViewHeader} from "../single-view-case-header/SingleCaseViewHeader";
import {fetchRelocationCaseProfile, isCaseOpen} from "../../store/caseProfileSlice";
import {getUploadedFiles} from "../../store/fileUploadSlice";
import {RootState, useAppDispatch} from "../../store/store";
import {CaseNotes} from "../single-view-case-notes/CaseNotes";
import {CaseHistory} from "../single-view-case-history/CaseHistory";
import {Outlet, useNavigate} from "react-router-dom";
import {setActiveTab} from "../../store/navigationTabSlice";
import {NAVIGATION_TABS} from "appConstants";
import styles from "./CaseContainer.module.scss";
import cx from "classnames";
import {Alert, Spin, Badge} from "antd";
import {isNil} from "lodash";
import {fetchCaseLookups} from "../../store/queueSlice";

export const CaseContainer = () => {
  const {case_id: id} = useParams<{case_id: string}>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const relocationCaseId = parseInt(id || "");
  const relocationCase = useSelector((state: RootState) => state.caseProfile.relocationCase) as any;
  const reservation = useSelector((state: RootState) => state.caseProfile.reservation);
  const activeTab = useSelector((state: RootState) => state.navigationTabs.activeTab);
  const files = useSelector((state: RootState) => state.fileUploader.files);
  const caseResolutions = useSelector((state: RootState) => state.queue.lookups.resolutions);

  const activateTab = async (tabIdentifier: string) => {
    await dispatch(setActiveTab(tabIdentifier));
    navigate(`${tabIdentifier}`);
  };

  const selectTabOnMount = () => {
    //If the case has status "done" is redirected to the wrap-up tab, otherwise is redirected to initiation tab
    if (!isNil(relocationCase) && !isCaseOpen(relocationCase?.attributes)) {
      dispatch(setActiveTab(NAVIGATION_TABS.WRAPUP));
      navigate(NAVIGATION_TABS.WRAPUP);
    } else {
      dispatch(setActiveTab(NAVIGATION_TABS.INITIATION));
      navigate(NAVIGATION_TABS.INITIATION);
    }
  };

  useEffect(() => {
    selectTabOnMount();
  }, [relocationCase?.attributes?.relocation_case_status_id]);

  useEffect(() => {
    dispatch(fetchRelocationCaseProfile(relocationCaseId));
    dispatch(getUploadedFiles(relocationCaseId));
    dispatch(fetchCaseLookups());
  }, []);

  // if no case and reservation keep loading
  if (!relocationCase || !reservation || !caseResolutions.length) {
    return (
      <div className={styles.waitSpinner}>
        <Spin size="large">
          <Alert message="Loading Case Information" description="We are preparing all the information you need." type="info" />
        </Spin>
      </div>
    );
  }

  return (
    <>
      {/** HEADER */}
      <SingleCaseViewHeader relocationCase={relocationCase} reservation={reservation} />

      {/** CONTAINER */}
      <div className={styles.container}>
        {/** LEFT SIDE */}
        <div className="">
          <div className={styles.tabs}>
            <div
              className={cx(styles.link, activeTab === NAVIGATION_TABS.INITIATION ? styles.active : "")}
              onClick={() => activateTab(NAVIGATION_TABS.INITIATION)}
            >
              Initiation & Follow Up
            </div>

            <div
              className={cx(styles.link, activeTab === NAVIGATION_TABS.FILES ? styles.active : "")}
              onClick={() => activateTab(NAVIGATION_TABS.FILES)}
            >
              <Badge size="small" count={files.length} offset={[15, 0]}>
                Attachments
              </Badge>
            </div>

            <div
              className={cx(styles.link, activeTab === NAVIGATION_TABS.WRAPUP ? styles.active : "")}
              onClick={() => activateTab(NAVIGATION_TABS.WRAPUP)}
            >
              Wrap Up
            </div>
          </div>
          <Outlet />
        </div>

        {/** RIGHT SIDE **/}
        <div className="right">
          <CaseNotes />
          <CaseHistory />
        </div>
      </div>
    </>
  );
};
