import {RelocationCase, RelocationCaseHistory} from "../../typing";
import {CaseHistoryActions} from "appConstants";
import _ from "lodash";
import {FrontendConfiguration} from "@common/configuration";
import {capitalizeFirstLetter} from "@common/utils";
import * as timezone from "dayjs/plugin/timezone";
import * as utc from "dayjs/plugin/utc";
import * as dayjs from "dayjs";
dayjs.extend(utc);
dayjs.extend(timezone);

export const getHistoryActionNumber = (caseHistory: RelocationCaseHistory, caseHistoryList: RelocationCaseHistory[]) => {
    switch (caseHistory.attributes.relocation_case_history_action_id) {
        case CaseHistoryActions.FOLLOWUP:
            const casesList = _.sortBy(caseHistoryList, ["attributes.created_at"]).filter(
                (e) => e.attributes.relocation_case_history_action_id === caseHistory.attributes.relocation_case_history_action_id
            );
            const index = _.findIndex(casesList, ["id", caseHistory.id]);
            return index >= 0 ? index + 1 : 0;
        default:
            return null;
    }
};

export const getLastHistoryActionNumber = (relocation_case_history_action_id: number, caseHistoryList: RelocationCaseHistory[]) => {
    switch (relocation_case_history_action_id) {
        case CaseHistoryActions.FOLLOWUP:
            const casesList = _.sortBy(caseHistoryList, ["attributes.created_at"]).filter(
                (e) => e.attributes.relocation_case_history_action_id === relocation_case_history_action_id
            );
            return casesList.length;
        default:
            return null;
    }
};

export const isHistoryActionWithNote = (caseHistory: RelocationCaseHistory) =>
    [3, 4, 6].includes(caseHistory.attributes?.relocation_case_history_action_id);

export const isTodayOrYesterday = (date: string): string => {
    const TODAY = dayjs().startOf("d");
    const YESTERDAY = dayjs().subtract(1, "d").startOf("day");
    const dateToCompare = dayjs(date).tz(FrontendConfiguration.getTimeZone()).toISOString();
    if (TODAY.isSame(dateToCompare, "day")) return "Today";
    if (YESTERDAY.isSame(dateToCompare, "day")) return "Yesterday";
    return "";
};

export const getHistorySubNote = (caseHistory: RelocationCaseHistory): string => {
    let words = [];
    const {email, phone, text} = caseHistory.attributes?.meta ?? {};
    if (email) words.push("emailed");
    if (text) words.push("texted");
    if (phone) words.push("phoned");
    if (words.length > 0) {
        words[0] = capitalizeFirstLetter(words[0]);
        if (words.length > 1) words[words.length - 1] = `and ${words[words.length - 1]}`;
        return `${words.length > 2 ? words.join(", ") : words.join(" ")} Guest`;
    }
    return "";
};

export const insertFakeEvents = (relocationCase: RelocationCase, caseHistoryList: RelocationCaseHistory[]) => [
    ...caseHistoryList,
    {
        type: "relocation_case_history",
        id: 0,
        attributes: {
            id: 0,
            relocation_case_id: relocationCase.id,
            relocation_case_history_action_id: CaseHistoryActions.SUBMIT,
            value: "oscar.yanez@vacasa.com",
            created_at: relocationCase.attributes?.created_at,
            updated_at: "",
            created_by: "",
        },
    } as RelocationCaseHistory,
];
