import {createSlice} from "@reduxjs/toolkit";
import {PriorityItem} from "@common/typing";
import {BULK_SELECTION_LIMIT} from "appConstants";
import {message} from "antd";

export type selectedCaseType = {
    id: number;
    legacy_reservation_id: number;
    priority: PriorityItem;
};

type initialStateType = {
    casesSelected: selectedCaseType[];
};

const initialState: initialStateType = {
    casesSelected: [],
};

const bulkSelectionSlice = createSlice({
    name: "bulkSelection",
    initialState,
    reducers: {
        addCasesSelection: (state, {payload}: {payload: selectedCaseType[]}) => {
            if (state.casesSelected.length + payload.length > BULK_SELECTION_LIMIT) message.error("Cannot select more because it exceeds the limit.");
            else state.casesSelected = [...state.casesSelected, ...payload.filter((p) => !state.casesSelected.map((_) => _.id).includes(p.id))];
        },
        removeCasesSelection: (state, {payload}: {payload: selectedCaseType[]}) => {
            state.casesSelected = state.casesSelected.filter((c) => !payload.map((_) => _.id).includes(c.id));
        },
        resetCasesSelection: (state) => {
            state.casesSelected = [];
        },
    },
});

export const {addCasesSelection, removeCasesSelection, resetCasesSelection} = bulkSelectionSlice.actions;

export default bulkSelectionSlice.reducer;
